import React from 'react';
import classnames from 'classnames';

import './CmpInput.style.scss';

export const CmpInput = (props) => {
  const { label, error, placeholder, inputType, field, width, prop } = props;

  return (
    <div className={classnames('cmp-input', width)}>
      <p className="cmp-input-label">{label}</p>
      <input className={'cmp-input-field'} placeholder={placeholder} type={inputType} {...field} {...prop} />
      {error && <p className={'cmp-input-error'}>{error}</p>}
    </div>
  );
};
