import React, { forwardRef } from 'react';
import Calendar from 'mdi-react/CalendarMonthIcon';

import './style.scss';

const DateInput = forwardRef(({ value = '', onClick = () => {} }, ref) => (
  <div className="calendar" ref={ref} onClick={onClick}>
    <span className="calendar-icon"> {value} </span>
    <Calendar />
  </div>
));

DateInput.displayName = 'DateInput';

export default DateInput;
