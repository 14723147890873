import React, { useCallback } from 'react';
import DamageCard from '../../Damage/Card';
import LazyLoadingList from '@components/LazyLoadingList';
import './index.scss';

const CardListDamage = ({
  loading = false,
  damages = [],
  lastPage = false,
  isEndReached = () => {},
  onDamageCardClick = () => {}
}) => {
  /**
   * Render single Car Check card
   * @param {object} damage - carCheck object
   * @param {number} i - index of element
   * @returns {JSX.Element}
   **/
  const renderCardListDamage = (damage, i) => {
    const { id, appState, damageNumber, vehicleId, userAvatar, date, driverName, driverSurname } = damage;

    return (
      <DamageCard
        key={i}
        state={appState}
        damageNumber={damageNumber}
        vehicleId={vehicleId}
        userAvatar={userAvatar}
        date={date}
        driverName={driverName}
        driverSurname={driverSurname}
        onClick={() => onDamageCardClick(id)}
      />
    );
  };

  /**
   * Render Lazy Loading Card list
   * @returns {JSX.Element}
   **/
  const renderContent = useCallback(() => {
    return (
      <LazyLoadingList
        data={damages || []}
        loading={loading}
        isLastPage={lastPage}
        onEndReached={isEndReached}
        renderItem={renderCardListDamage}
      />
    );
  }, [loading, damages]);

  return <>{renderContent()}</>;
};

export default CardListDamage;
