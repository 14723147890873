import * as React from 'react';

const TireIcon = ({ fill = 'red' }) => {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.985 8.587c0-.02 0-.041-.021-.041l-2.55-1.153c-.02.205-.041.411-.041.617 0 1.092.39 2.1 1.007 2.883l1.605-2.306zM7.98 10.11h-.186L5.82 12.089c.658.35 1.399.556 2.18.556.617 0 1.193-.124 1.728-.33l-1.749-2.203zM6.766 8.01c0 .68.555 1.236 1.234 1.236.679 0 1.234-.556 1.234-1.236 0-.68-.555-1.235-1.234-1.235-.679 0-1.234.556-1.234 1.235zM6.745 6.322c.021-.02.042-.041.083-.062l.247-2.78A4.66 4.66 0 004.03 5.642l2.715.68zM9.933 8.813l1.337 2.471a4.61 4.61 0 001.357-3.274c0-.144 0-.288-.02-.412l-2.592 1.05c-.04.042-.061.104-.082.165zM9.172 6.26c.062.041.103.082.144.103l2.735-.556a4.658 4.658 0 00-3.146-2.348l.267 2.801z"
        fill={fill}
      />
      <path
        d="M8 16c-4.422 0-8-3.583-8-7.99C0 3.583 3.578 0 8 0c4.401 0 8 3.583 8 8.01-.02 4.407-3.599 7.99-8 7.99zM8 2.368c-3.105 0-5.635 2.533-5.635 5.642 0 3.11 2.53 5.643 5.635 5.643s5.635-2.533 5.635-5.643C13.615 4.9 11.085 2.368 8 2.368z"
        fill={fill}
      />
    </svg>
  );
};

export default TireIcon;
