import React from 'react';

/** load components **/
import Toggler from '@components/Common/Toggler';
import { Button } from '@components/Common/Button';
import { BUTTON_TYPES } from '@constants';

/** load icons **/
import CloseIcon from 'mdi-react/AlertCircleOutlineIcon';
import CheckIcon from 'mdi-react/CheckIcon';

/** load tt tool **/
import { t } from '@helpers/i18n';
import { ButtonList } from '@components/Common/Button/ButtonList';
import Grid from '@mui/material/Grid';
import './Settings.style.scss';
import { Field, Formik } from 'formik';

const Settings = ({ isAssign = false, saveProfile, onToggleChange = () => {}, profile, children }) => {
  return (
    <Grid item xs={7}>
      <Formik
        enableReinitialize
        initialValues={{
          language: profile.language
        }}>
        {({ values }) => (
          <form
            className={'profile-settings'}
            onSubmit={(e) => {
              e.preventDefault();
            }}>
            <div className={'profile-settings-row'}>
              <div className="settings-toggle ">
                <h1>{t('settings')}</h1>
                <div className="language-form">
                  <label className="language-label">
                    <p>{t('language')}</p>
                  </label>
                  <Field className={'select-box'} label={t('title')} component={'select'} name="language">
                    <option value="en-GB">{t('option_lang_en')}</option>
                    <option value="bg-BG">{t('option_lang_bg')}</option>
                    <option value="de-DE">{t('option_lang_de')}</option>
                    <option value="pl-PL">{t('option_lang_pl')}</option>
                    <option value="tr-TR">{t('option_lang_tr')}</option>
                    <option value="ro-RO">{t('option_lang_ro')}</option>
                  </Field>
                </div>
                <div className="settings-question">
                  <div className={'driver-assign'}>
                    <span>{t('can_driver_assign')}</span>
                    <label className="settings-answer ">
                      <Toggler id="satellite" onChange={onToggleChange} checked={isAssign} />
                    </label>
                  </div>

                  <div className="settings-notification">
                    <div className="settings-info">
                      <p>
                        <CloseIcon />
                      </p>
                      <div>{t('managers_always_assign')}</div>
                    </div>
                  </div>
                </div>
                <p className="settings-question changed">{t('last_changed')}</p>
              </div>
            </div>
            <ButtonList column>
              {children}
              <Button
                className="is-success"
                icon={<CheckIcon />}
                name="success"
                value="btn-success"
                type={BUTTON_TYPES.SUCCESS}
                text={t('save')}
                isSubmit
                onClick={() => saveProfile(values)}
              />
            </ButtonList>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default Settings;
