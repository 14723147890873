import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/loader.json';

import './AnimatedLoader.styles.scss';

const AnimatedLoader = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="animated-fl-loader">
      <Lottie options={defaultOptions} height={500} width={500} />
    </div>
  );
};

export default AnimatedLoader;
