/**
 * It takes a number, converts it to a string, and returns it with a currency symbol
 * @param amount - The amount to be formatted.
 * @param [language=de-DE] - The language to use for the locale.
 * @param [currency=EUR] - The currency to use.
 * @returns A string with the amount formatted as currency.
 */
export const currencyToLocaleFormatter = (amount, language = 'de-DE', currency = 'EUR') => {
  if (amount) {
    return Number(amount).toLocaleString(language, { style: 'currency', currency: currency });
  } else {
    return '-';
  }
};

const isValidNumber = (value) => {
  const number = parseFloat(value);
  return !isNaN(number) && isFinite(number);
};

export const isValidLatLng = (lat, lng) => {
  const latitude = parseFloat(lat);
  const longitude = parseFloat(lng);

  return (
    isValidNumber(latitude) &&
    isValidNumber(longitude) &&
    latitude >= -90 &&
    latitude <= 90 &&
    longitude >= -180 &&
    longitude <= 180
  );
};
