import React from 'react';
import Avatar from '@components/User/Avatar';
import { getColor } from '@components/Card/include';
import { getDamageStateCardColor, getDamageStateIcon } from '@helpers/common';

const DamageAvatar = ({ userAvatar = '', state = '' }) => {
  const getIcon = () => {
    if (state) {
      const param = state.toLowerCase();
      return getDamageStateIcon(param);
    }
  };

  const getIconBgColor = () => {
    const param = state.toLowerCase();
    const colorCard = getDamageStateCardColor(param);
    return getColor(colorCard);
  };

  return <Avatar iconBackgroundColor={getIconBgColor()} icon={getIcon()} src={userAvatar} small />;
};

export default DamageAvatar;
