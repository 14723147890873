import React from 'react';
import { CARD_TYPES } from '@components/Card';

import SimpleCard from '@components/Card/SimpleCard';
import DriverInfo from './DriverInfo';
import DamageInfo from './DamageInfo';
import DamageAvatar from './DamageAvatar';
import { getDamageStateCardColor } from '@helpers/common';

const DamageCard = ({
  state,
  damageNumber,
  vehicleId,
  userAvatar,
  date,
  driverName,
  driverSurname,
  onClick = () => {}
}) => {
  /**
   * return proper class for right border of damage card
   **/
  const checkCardColor = () => {
    if (state) {
      const param = state.toLowerCase();

      const colorCard = getDamageStateCardColor(param);
      return `damage_border_${colorCard}`;
    }
    return CARD_TYPES.WHITE;
  };

  /**
   * Render Damage Card Left Block
   **/
  const renderLeft = () => <DamageAvatar state={state} userAvatar={userAvatar} />;
  /**
   * Render Damage Card Right Block
   **/
  const renderRight = () => <DamageInfo date={date} damageNumber={damageNumber} vehicleId={vehicleId} />;
  /**
   * Render Damage Card Center Block
   **/
  const renderPrimaryContent = () => <DriverInfo driverName={driverName} driverSurname={driverSurname} />;

  return (
    <>
      <SimpleCard
        onClick={onClick}
        className={`damage-card ${checkCardColor()}`}
        renderRight={renderRight}
        renderLeft={renderLeft}
        primaryContent={renderPrimaryContent}
      />
    </>
  );
};

export default DamageCard;
