import React from 'react';
import './ReportingCard.styles.scss';

function ReportingCardComponent(props) {
  const { componentStyle = {}, headerContentLeft, headerContentRight, additionalClass = '', children } = props;

  return (
    <div className={`card-container ${additionalClass}`} style={componentStyle}>
      <div className={`reporting-card-header`}>
        {headerContentLeft}
        {headerContentRight}
      </div>
      {children}
    </div>
  );
}
export default ReportingCardComponent;
