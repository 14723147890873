import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState, useCallback } from 'react';
import * as tripsActions from '@store/trips/actions';
import dayjs from 'dayjs';

/** Loading components **/
import MapContainer from '@containers/Map';
import SearchBarFilter from '@components/Common/SearchBar/withFilters';
import TripCard from '@components/Trip/Card';
import LazyLoadingList from '@components/LazyLoadingList';
import TripFilter from '@components/Trip/Filter/TripFilter.component';

/** Loading formatters **/
import { parseISO, format } from 'date-fns';
import { formatDate } from '@services/formatters';
import { DATE_FORMATS, STORAGE_KEYS } from '@helpers/constants';

const TripsListContainer = () => {
  /**
   * Redux selectors and dispatch
   */
  const { tracksPerDay, drivers, loading, lastPage, listNeedsRefreshing } = useSelector((state) => state.trips);
  const { auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  /**
   * Navigation history hook
   */
  const history = useHistory();
  const twoWeeksInPast = dayjs().subtract(14, 'day').toDate();
  const today = new Date();
  const storageFilters = localStorage.getItem(STORAGE_KEYS.trips)
    ? JSON.parse(localStorage.getItem(STORAGE_KEYS.trips))
    : { search: '', query: '' };
  const initialQuery = `fromDate=${formatDate(twoWeeksInPast, DATE_FORMATS.full_YMD_dash)}&toDate=${formatDate(today, DATE_FORMATS.full_YMD_dash)}`;

  /**
   * React State
   * filter parameters
   */
  const [filterQuery, setFilterQuery] = useState(storageFilters?.query || initialQuery);
  const [tripsSearch, setTripsSearch] = useState(storageFilters?.search || '');
  const [page, setPage] = useState(0);

  // if all filters are selected, we will not include them in the query string, that is why we turn them if they are missing from filter
  const [settings, setSettings] = useState([
    {
      id: 0,
      title: 'all',
      filter: 'all',
      value:
        !storageFilters.query ||
        !storageFilters.query.includes('isCompleted') ||
        storageFilters.query.match(/isCompleted=[0,1]/g)
    },
    {
      id: 1,
      title: 'complete',
      filter: 'isCompleted=1',
      value:
        !storageFilters.query ||
        !storageFilters.query.includes('isCompleted') ||
        storageFilters.query.includes('isCompleted=1')
    },
    {
      id: 2,
      title: 'incomplete',
      filter: 'isCompleted=0',
      value:
        !storageFilters.query ||
        !storageFilters.query.includes('isCompleted') ||
        storageFilters.query.includes('isCompleted=0')
    },
    {
      id: 3,
      title: 'trips_between',
      filter: 'trips_between',
      value: true
    }
  ]);
  const [dateFrom, setDateFrom] = useState(twoWeeksInPast);
  const [dateTo, setDateTo] = useState(today);
  const [showSettings, setShowSettings] = useState(false);
  const [filtersChanged, setFiltersChanged] = useState(false);

  /**
   * Fetches first page of trips
   */
  const fetchTripsInitial = () => {
    if (listNeedsRefreshing || filtersChanged) {
      setFiltersChanged(false);
      setPage(0);
      dispatch(
        tripsActions.tripsGet({
          userId: auth.user.id,
          page: 0,
          filter: filterQuery,
          search: tripsSearch
        })
      );
    }
  };

  /**
   * It takes two arguments, query and search, and saves them to localStorage
   * @param query - The query string that is used to filter the list of items.
   * @param search - The search object that contains the search parameters.
   */
  const saveFilters = (query, search) => {
    localStorage.setItem(STORAGE_KEYS.trips, JSON.stringify({ query, search }));
  };

  /**
   * Fetching trips effect
   **/
  useEffect(() => {
    fetchTripsInitial();
    saveFilters(filterQuery, tripsSearch);
  }, [auth, filterQuery, tripsSearch]);

  /**
   * On Trips Card Click redirect to Trip's details information
   * @param trip
   */
  const onTripsCardClick = (trip) => {
    const { carId, end } = trip;

    if (end) {
      const formattedEndDate = format(parseISO(end), DATE_FORMATS.full_YMD_dash);
      history.push(`/trips/${carId}/info/${formattedEndDate}`);
    } else {
      history.push(`/trips/${carId}/info`);
    }
  };

  /**
   * set trips search local state
   * @param value
   */
  const onTripsSearch = (value) => {
    setFiltersChanged(true);
    setTripsSearch(value);
  };

  /**
   * On Trips Filter value change
   * @param {string} key - filter key
   **/
  const onSettingsChange = (key) => {
    const newFilter = [...settings];
    let i = 0;
    if (Object.is(key, 'all') && settings[0].value) {
      newFilter.forEach((filter) => (filter.value = false));
    } else if (Object.is(key, 'all') && !settings[0].value) {
      newFilter.forEach((filter) => (filter.value = true));
    } else {
      for (i = 1; i < newFilter.length; i++) {
        if (Object.is(newFilter[i].title, key)) {
          newFilter[i].value = !newFilter[i].value;
        }
      }
    }
    newFilter[0].value =
      Object.is(Object.is(newFilter[1].value, newFilter[2].value), newFilter[3].value) &&
      newFilter[1].value &&
      newFilter[2].value &&
      newFilter[3].value;
    setSettings(newFilter);
  };

  /**
   * on toggle button click show or disappear Trips filters
   */
  const onSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  /**
   * It sets the dateFrom or dateTo state variable depending on the name parameter
   * @param name - The name of the date picker.
   * @param date - The date that was selected
   */
  const onDateChange = (name, date) => {
    Object.is(name, 'dateFrom') && setDateFrom(date);
    Object.is(name, 'dateTo') && setDateTo(date);
  };

  /** Preform Trips filtering **/
  const onApplyClick = () => {
    let filter = [];
    if (settings[1].value && !settings[2].value) {
      filter.push(`${settings[1].filter}`);
    }
    if (!settings[1].value && settings[2].value) {
      filter.push(`${settings[2].filter}`);
    }
    if (settings[0].value && settings[1].value && settings[2].value) {
      filter = [];
    }
    if (settings[3].value && dateFrom && dateTo) {
      filter.push(
        `fromDate=${format(dateFrom, DATE_FORMATS.full_YMD_dash)}&toDate=${format(dateTo, DATE_FORMATS.full_YMD_dash)}`
      );
    } else {
      filter.push(
        `fromDate=${format(twoWeeksInPast, DATE_FORMATS.full_YMD_dash)}&toDate=${format(today, DATE_FORMATS.full_YMD_dash)}`
      );
    }
    setFilterQuery(filter.join('&'));
    setShowSettings(false);
    setFiltersChanged(true);
  };

  /**
   * Fetch trips on end page reached
   */
  const isEndReached = () => {
    setPage(page + 1);
    dispatch(
      tripsActions.tripsLoadMore({
        userId: auth.user.id,
        page: page + 1,
        search: tripsSearch,
        filter: filterQuery
      })
    );
  };

  /**
   * Render single Trip card
   * @param {object} trip - trip object
   * @param tripIndex
   * @returns {JSX.Element}
   **/
  const renderCardListTrip = (trip, tripIndex) => {
    if (Object.prototype.hasOwnProperty.call(trip, 'start') && trip.start !== null) {
      return (
        <TripCard trip={trip} key={tripIndex.toString()} onClick={() => onTripsCardClick(trip)} drivers={drivers} />
      );
    }
  };

  /**
   * Render Lazy Loading Card list
   * @returns {JSX.Element}
   **/
  const renderTripsList = useCallback(() => {
    return (
      <LazyLoadingList
        data={tracksPerDay}
        loading={loading}
        isLastPage={lastPage}
        onEndReached={isEndReached}
        renderItem={renderCardListTrip}
        hasBottom
      />
    );
  }, [loading, tracksPerDay]);

  /**
   * trips props for TripsFilter Component
   */
  const tripsFilterProps = {
    visible: showSettings,
    filterValues: settings,
    dateFrom: dateFrom,
    dateTo: dateTo,
    onDateChange: onDateChange,
    filterChange: onSettingsChange,
    applyFilter: onApplyClick
  };

  return (
    <div className={'list-wrapper'}>
      <MapContainer />
      <div className={'list-view'}>
        <SearchBarFilter
          onSearch={(e) => onTripsSearch(e.target.value)}
          search={tripsSearch}
          onFilterPress={onSettingsClick}
          onClose={() => onTripsSearch('')}>
          <TripFilter {...tripsFilterProps} />
        </SearchBarFilter>
        {renderTripsList()}
      </div>
    </div>
  );
};

export default TripsListContainer;
