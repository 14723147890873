import React, { useState } from 'react';

/** Load common Tab component **/
import Tabs from '@components/Common/Tabs';

import { t } from '@helpers/i18n';
/** Load tab components **/
import CarCheckCheckListPreview from './CarCheckCheckListPreview';
import CarCheckImageList from './CarCheckImageList';
import DamagesList from './DamagesList';

/** load component styles **/
import './style.scss';

const CarCheckTabs = ({ carCheck = {} }) => {
  // load carCheck tabs
  // if car check is master will load 3 tabs
  const tabs = +carCheck.isMaster
    ? [
        {
          id: 0,
          name: 'carChecks',
          transl: t('car-check')
        },
        {
          id: 1,
          name: 'damages',
          transl: t('damages')
        },
        { id: 2, name: 'checklist', transl: t('checklist') }
      ]
    : [
        {
          id: 0,
          name: 'carChecks',
          transl: t('car-check')
        },
        {
          id: 1,
          name: 'damages',
          transl: t('damages')
        }
      ];

  const [activeTab, setActiveTab] = useState(0);
  const [activeTabContent, setActiveTabContent] = useState('carChecks');

  const tabValues = {
    carChecks: <CarCheckImageList carCheckId={carCheck.id} carChecks={carCheck.images} />,
    checklist: <CarCheckCheckListPreview status={carCheck.status} license={carCheck.vehicleId} />,
    damages: <DamagesList damages={carCheck.damages} />
  };

  const tabClicked = (e, tab) => {
    e.stopPropagation();
    setActiveTabContent(tab.name);
    setActiveTab(tab.id);
  };

  /** renders tabContent of active tab **/
  const renderTabContent = () => {
    return tabValues[activeTabContent];
  };

  return (
    <Tabs tabClass={'car-check-tabs'} tabs={tabs} activeTab={activeTab} tabClicked={tabClicked}>
      <div className={'tab-content'}>{renderTabContent()}</div>
    </Tabs>
  );
};

export default CarCheckTabs;
