import React from 'react';
import { useSelector } from 'react-redux';
import SpeedometerIcon from 'mdi-react/SpeedometerIcon';
import GasStationIcon from 'mdi-react/GasStationIcon';
import Avatar from '@components/User/Avatar';
import './index.scss';

const DriverPopup = (props) => {
  const {
    username = '',
    userCar = '',
    customerCity = '',
    customerPlace = '',
    speed = 0,
    fuelLevel = 0,
    tags = []
  } = props;

  const { employees } = useSelector((state) => state.employees);
  const address = customerPlace.split(',');

  const getAvatarEmployee = () => {
    let userAvatar = '';

    employees.forEach((el) => {
      const userInfo = `${el.name} ${el.surname}`;
      if (userInfo === username) {
        userAvatar = el.avatar;
      }
    });
    return userAvatar;
  };

  const renderTags = () => {
    if (tags && tags?.length) {
      return <div className="info-tags">{tags.map((tag) => tag.name).join(' | ')}</div>;
    }
  };

  return (
    <div className="driver-popup">
      <div className="info-container">
        <div className="info-header">
          <p className="header-name">{username}</p>
          <p className="header-car">{userCar}</p>
          <div className="header-car" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            {renderTags()}
          </div>
        </div>
        <div className="popup-footer">
          <div className="customer-city">{customerCity}</div>
          <div className="customer-data">
            <div className="data-line">
              <div className="line-start">{address[0]}</div>
              <SpeedometerIcon />
              <div className="line-end">{speed} km/h</div>
            </div>
            <div className="data-line">
              <div className="line-start">{address[1]}</div>
              <GasStationIcon />
              <div className="line-end">{fuelLevel}%</div>
            </div>
          </div>
        </div>
        <Avatar small src={getAvatarEmployee()} />
        <div className="triangle" />
      </div>
    </div>
  );
};

export { DriverPopup };
