import React from 'react';

/** Load Icons **/
import IconSet from '@components/IconSet';
import CheckListIcon from 'mdi-react/PlaylistCheckIcon';
import FlashAlertIcon from 'mdi-react/FlashIcon';
import { LIGHT_COLOR_GREEN, LIGHT_COLOR_RED } from '@constants';
import TireIcon from '@assets/svg/tire_icon';

const CarCheckStatusIcons = ({ isMaster, isStatusOkay, damageCount, isTyreOk }) => {
  return (
    <IconSet>
      {/* Checklist Icon */}
      {parseInt(isMaster) > 0 && <CheckListIcon color={isStatusOkay ? LIGHT_COLOR_GREEN : LIGHT_COLOR_RED} />}
      {/* Damages Icon */}
      {parseInt(damageCount) > 0 && <FlashAlertIcon className={'isset'} color={LIGHT_COLOR_RED} />}
      {/* Tyres Icon */}
      <TireIcon fill={isTyreOk ? LIGHT_COLOR_GREEN : LIGHT_COLOR_RED} />
    </IconSet>
  );
};

export default CarCheckStatusIcons;
