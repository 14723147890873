import React, { useEffect, useState } from 'react';
import * as dailyRentActions from '@store/dailyRent/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { Spinner } from '@components/Spinner';
import CarCheckCard from '@components/CarCheck/Card';
import DailyRentNoReport from '@components/DailyRent/NoReportBox/DailyRentNoReport.component';
import { Button } from '@components/Common/Button';
import AlertCmp from '@components/Common/Alert/Alert.component';
import Loader from '@components/Common/Loader';
import { BUTTON_TYPES } from '@constants';
import { downloadDailyRentReport, fetchDailyRentReportFile } from '@services/utils/DailyRent.utils';

import DownloadIcon from 'mdi-react/DownloadOutlineIcon';
import { t } from '@helpers/i18n';
import '../index.scss';

// REPORT TYPE PROP CAN BE 'handover-report' OR 'return-report'
const DailyRentCarCheckReport = ({ reportType }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [pdfNotAvailablePopup, setPdfNotAvailablePopup] = useState(false);
  const params = useParams();
  const { reportLoading = true, handoverReport, returnReport } = useSelector((state) => state.dailyRent);
  const { carCheckId } = params;
  const dispatch = useDispatch();

  const reportFromStore = reportType === 'handover-report' ? handoverReport : returnReport;

  useEffect(() => {
    if (reportType === 'return-report') {
      carCheckId !== reportFromStore?.id && dispatch(dailyRentActions.dailyRentReturnReportGet({ carCheckId }));
    } else if (reportType === 'handover-report') {
      carCheckId !== reportFromStore?.id && dispatch(dailyRentActions.dailyRentHandoverReportGet({ carCheckId }));
    }
  }, [carCheckId, reportType]);

  const downloadContractPdf = async () => {
    if (!reportFromStore?.id || !reportFromStore.pdfReport.name) {
      setPdfNotAvailablePopup(true);
    } else {
      setDownloadLoading(true);
      const dailyRentFile = await fetchDailyRentReportFile(
        reportFromStore.id,
        'carcheck_pdf',
        reportFromStore.pdfReport.name
      );
      if (dailyRentFile) {
        downloadDailyRentReport(dailyRentFile);
      } else {
        setPdfNotAvailablePopup(true);
      }
      setDownloadLoading(false);
    }
  };

  if (reportLoading) {
    return <Spinner />;
  }

  return (
    <div className="daily-rent-report-container">
      {reportFromStore?.id ? (
        <>
          <CarCheckCard carCheck={reportFromStore} carCheckId={carCheckId} initiallyExpended />
          <div className="report-download-container">
            <Button
              className="report-download-button"
              text={t('registration-download')}
              onClick={() => downloadContractPdf()}
              type={BUTTON_TYPES.WARNING}
              icon={downloadLoading ? <Loader /> : <DownloadIcon />}
              disabled={downloadLoading}
              isFlat
            />
          </div>
          <AlertCmp
            visible={pdfNotAvailablePopup}
            title={t('report_not_available')}
            onClick={() => setPdfNotAvailablePopup(false)}
          />
        </>
      ) : (
        <DailyRentNoReport
          title={
            reportType === 'handover-report'
              ? t('dailyRentDetails.noHandoverReport')
              : t('dailyRentDetails.noReturnReport')
          }
          message={
            reportType === 'handover-report'
              ? t('dailyRentDetails.noHandoverReportExplanation')
              : t('dailyRentDetails.noReturnReportExplanation')
          }
        />
      )}
    </div>
  );
};

export default DailyRentCarCheckReport;
