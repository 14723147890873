import React, { useEffect, useRef } from 'react';
import LoadingCard from '@components/Card/LoadingCard/LoadingCard.component';
import EmptyListPopup from '../Common/NoResultsPopup';
import { TailSpin } from 'react-loader-spinner';

const LazyLoadingList = ({
  data = [],
  loading = true,
  isLastPage = true,
  onEndReached = () => {},
  renderItem = () => {},
  hasBottom
}) => {
  const loaderArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const loaderRef = useRef();

  // interaction observer options
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        onEndReached();
      }
    }, options);

    if (loaderRef && loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [data.length]);

  /**
   * Renders a list of skeleton loading cards
   * @returns {ReactDOMComponent[]}
   */
  const renderSkeletonLoading = () => {
    return loaderArray.map((item) => (
      <div style={{ marginBottom: '10px' }} key={item.toString()}>
        <LoadingCard amount={1} hasBottom={hasBottom} />
      </div>
    ));
  };

  /**
   * Renders components for given data array
   * @returns {ReactDOMComponent[]}
   */
  const renderItemsList = () => {
    return data.map((item, index) => renderItem(item, index));
  };

  return (
    <div className={'list-container'}>
      {loading && renderSkeletonLoading()}

      {!loading && data.length > 0 && renderItemsList()}

      {!loading && data.length >= 1 && !isLastPage && (
        <div ref={loaderRef}>
          <TailSpin color="#FFF" height={20} width={20} />
        </div>
      )}
      {!loading && data.length === 0 && <EmptyListPopup />}
    </div>
  );
};

export default LazyLoadingList;
