import React from 'react';

/** Load components **/
import Toggler from '@components/Common/Toggler';
import { t } from '@helpers/i18n';
import FilterDatePicker from '@components/Common/FilterDatePicker';

const ContractFilterForm = ({ filters = [], contractDates = {}, onChange = () => {}, onDateChange = () => {} }) => {
  return (
    <div className={'filter-rows'}>
      <div className="toggle">
        {filters.map((filterItem, index) => (
          <label key={index}>
            <p>{t(filterItem.filter)}</p>
            <Toggler id={index.toString()} checked={filterItem.value} onChange={onChange} />
          </label>
        ))}
        {filters[3].value && (
          <div className="date-picker">
            <FilterDatePicker
              dateFrom={contractDates.dateFrom}
              dateTo={contractDates.dateTo}
              onDateChange={onDateChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContractFilterForm;
