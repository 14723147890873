import React from 'react';

import './index.scss';

const DataForm = ({ data = [] }) => {
  return (
    <div className="data-form">
      {data.map((el, i) => {
        return (
          <div key={i} className={`form-line form-line_${el.color}`}>
            <div className="form-name">{el.name}:</div>
            <div className="form-value icon-user-state">
              {el.value ? el.value : '-'} {el.icon}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DataForm;
