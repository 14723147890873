import React from 'react';
/** Load translation helper **/
import { t } from '@helpers/i18n';
/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';
import VehicleFilterForm from './FilterForm';

const VehicleFilter = (props) => {
  const { visible = false, filterValues = [], filterChange, applyFilter = () => {}, employeeFilterTitle } = props;

  return (
    visible && (
      <div className={'list-filter'}>
        {/** Filter form **/}
        <VehicleFilterForm employeeFilterTitle={employeeFilterTitle} settings={filterValues} onChange={filterChange} />

        <ApplyButton onClick={applyFilter} text={t('apply')}></ApplyButton>
      </div>
    )
  );
};

export default VehicleFilter;
