import { requestMs } from '@helpers/api';

export const users = {
  getUserData: (userId) => requestMs.get(`api3/user/${userId}`),
  getUserDataLicence: (userId) => requestMs.get(`api3/user/${userId}/driverlicence`),
  putUserDataLicence: (userId, data) => requestMs.put(`/api3/user/${userId}/driverlicence`, data),
  getAvatar: (userId) => requestMs.get(`api3/user/${userId}/avatar`),

  changeAvatar: ({ id, avatar }) => requestMs.put(`api3/user/${id}/avatar`, { avatar }),
  putUserData: (userData) => requestMs.put(`api3/user/${userData.id}`, userData),
  setUserCreate: (userData) => requestMs.post(`api3/user/create`, { ...userData }),
  resetPassword: (username) => requestMs.post('api3/user/forget', { username }),
  challengeReset: (resetData) => requestMs.post('api3/user/challenge-reset', resetData),
  deleteUser: (userId) => requestMs.delete(`api3/user/${userId}`, {}),

  getTagsPerUser: (userId) => requestMs.get(`/api3/user/${userId}/tag`),

  getPersonalId: (userId) => requestMs.get(`/api3/user/${userId}/driverid`),
  updatePersonalId: (userId, data) => requestMs.put(`/api3/user/${userId}/driverid`, data)
};
