import React, { useMemo } from 'react';
import LazyLoadingList from '@components/LazyLoadingList';
import DailyRentCard from '@components/DailyRent/Card/DailyRentCard.component';

const DailyRentCardList = ({
  loading = false,
  dailyRents = [],
  isLastPage = false,
  isEndReached = () => {},
  onRentClick = () => {}
}) => {
  const renderDailyRentCard = (rent, i) => <DailyRentCard rent={rent} key={i} onRentClick={onRentClick} />;

  const renderContent = useMemo(
    () => (
      <LazyLoadingList
        data={dailyRents || []}
        loading={loading}
        isLastPage={isLastPage}
        onEndReached={isEndReached}
        renderItem={renderDailyRentCard}
      />
    ),
    [loading, dailyRents]
  );

  return <>{renderContent}</>;
};

export default React.memo(DailyRentCardList);
