import React, { createRef } from 'react';

import LicenseInput from './LicenseInput/LicenseInput.component';
import { ReactComponent as EuroLogo } from '../icons/euro-flag.svg';

import './LicensePlate.styles.scss';

const LicensePlate = ({ license = '', licenseChange, disabled, country = 'D' }) => {
  const inputFirstRef = createRef();
  const inputSecondRef = createRef();
  const inputThirdRef = createRef();

  const splitInput = license.split(/[- ]/);

  const formatForDisplay = (input) => {
    const inputData = input;
    const itemIndexes = [0, 1, 2];
    if (input.length < 3) {
      itemIndexes.forEach((index) => {
        if (!input[index]) {
          inputData[index] = '';
        }
      });
      return inputData;
    }
    return input;
  };

  const licenseDigits = formatForDisplay(splitInput);

  const inputsAttributes = [
    {
      name: 'firstInput',
      maxLength: 3,
      ref: inputFirstRef
    },
    {
      name: 'secondInput',
      maxLength: 4,
      ref: inputSecondRef
    },
    {
      name: 'thirdInput',
      maxLength: 5,
      ref: inputThirdRef
    }
  ];

  const onChange = (e) => {
    const nodeValue = e.target.value;
    const nodeName = e.target.name;
    const findInputField = (element) => element.name === nodeName;
    const fieldIndex = inputsAttributes.findIndex(findInputField);
    licenseDigits[fieldIndex] = nodeValue;
    const updatedLicense = `${licenseDigits[0]}-${licenseDigits[1]} ${licenseDigits[2]}`;
    licenseChange(updatedLicense);
  };

  const [firstInput, secondInput, thirdInput] = inputsAttributes;

  return (
    <div className={'vehicle-license-plate'}>
      <div className="country-container">
        <EuroLogo />
        {country}
      </div>
      <fieldset disabled={disabled} style={{ display: 'flex', alignItems: 'center' }}>
        <div className="license-container">
          <LicenseInput
            name={firstInput.name}
            value={licenseDigits[0]}
            maxLength={firstInput.maxLength}
            ref={firstInput.ref}
            onChange={(e) => onChange(e, inputSecondRef)}
            required
          />
          <div className={'separator'}>
            <svg height="14" width="14" style={{ paddingBottom: '1px' }}>
              <circle cx="7" cy="7" r="50%" fill="#deeaf1" />
            </svg>
            <svg height="20" width="20">
              <circle cx="10" cy="10" r="50%" fill="#deeaf1" />
            </svg>
          </div>
          <LicenseInput
            name={secondInput.name}
            value={licenseDigits[1]}
            maxLength={secondInput.maxLength}
            ref={secondInput.ref}
            onChange={(e) => onChange(e, inputThirdRef)}
            required
          />
          <LicenseInput
            name={thirdInput.name}
            value={licenseDigits[2]}
            maxLength={thirdInput.maxLength}
            ref={thirdInput.ref}
            onChange={(e) => onChange(e, inputFirstRef)}
            required
          />
        </div>
      </fieldset>
    </div>
  );
};

export default LicensePlate;
