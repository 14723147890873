import React, { forwardRef } from 'react';

import './LicenseInput.styles.scss';

const LicenseInput = forwardRef((props, ref) => {
  // passing the ref to a DOM element,
  // so that the parent has a reference to the DOM node
  return (
    <div className={'license-input-wrapper'}>
      <input className={'license-input'} {...props} type={'text'} ref={ref} />
      <span className="border"></span>
    </div>
  );
});
LicenseInput.displayName = 'LicenseInput';

export default LicenseInput;
