import React from 'react';
/** Load translation helper **/
import { t } from '@helpers/i18n';
/** load components **/
import DamageFilterForm from './FilterForm';
import ApplyButton from '@components/Common/Button/ApplyButton';

const DamageFilter = ({
  visible = false,
  dateTo = null,
  dateFrom = null,
  onDateChange = () => {},
  filterValues = [],
  filterChange = () => {},
  applyFilter = () => {}
}) => {
  return (
    visible && (
      <div className={'list-filter'}>
        {/** Filter form **/}
        <DamageFilterForm
          settings={filterValues}
          onChange={filterChange}
          dateTo={dateTo}
          dateFrom={dateFrom}
          onDateChange={onDateChange}
        />
        {/** Filter submit btn
         * todo switch with component
         **/}
        <ApplyButton onClick={applyFilter} text={t('apply')}></ApplyButton>
      </div>
    )
  );
};

export default DamageFilter;
