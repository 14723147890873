import React from 'react';
/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';

/** import services **/
import { DailyRentsFilterFormatter } from '@services/formatters/dailyRent/DailyRentFilter.formatter';
import { t } from '@helpers/i18n';
import DailyRentFilterForm from '@components/DailyRent/Filter/FilterForm/DailyRentFilterForm.component';

const DailyRentFilter = ({
  filterValues = [],
  visible = false,
  rentDates = {},
  onDateChange = () => {},
  filterChanged = () => {},
  applyFilter = () => {}
}) => {
  const filterChange = (e) => {
    const updatedFilters = DailyRentsFilterFormatter(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible && (
      <div className={'list-filter'}>
        <DailyRentFilterForm
          rentDates={rentDates}
          filters={filterValues}
          onChange={filterChange}
          onDateChange={onDateChange}
        />
        <ApplyButton onClick={applyFilter} text={t('apply')} />
      </div>
    )
  );
};

export default DailyRentFilter;
