import Marker, { MARKER_TYPES } from './Marker';
import GoogleMapReact from 'google-map-react';
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { isValidLatLng } from '@services/formatters/util/Number.formatters';
import { ReactComponent as ServicePartnerMarker } from '@assets/svg/map-marker-service-partner.svg';
import { ReactComponent as CarMarker } from '@assets/svg/map-marker-car.svg';
import { ReactComponent as VanMarker } from '@assets/svg/map-marker-van.svg';
import { lineSymbol, priceLevel } from '@services/utils/Home.utils';

const MAX_WAYPOINTS_PER_REQUEST = 20;

const Map = (props) => {
  const {
    vehicles,
    stations,
    servicePartners,
    defaultZoom,
    defaultCenter,
    onMapBoundsChange,
    onMarkerClick,
    settings,
    trip,
    loadingTrip,
    activeVehicle
  } = props;

  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const [mapState, setMapState] = useState();
  const ref = useRef([]);
  const startMarkerRef = useRef(null);
  const [mapLoaderVisible, setMapLoaderVisible] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (loadingTrip || mapLoaderVisible) {
      // If we're either loading the trip or drawing the route, ensure loader is visible
      setShowLoader(true);
    } else {
      // If both are false, wait a short moment before hiding the loader
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 100);
      return () => clearTimeout(timeout);
    }
  }, [loadingTrip, mapLoaderVisible]);

  const renderMarkerIcon = (type) => {
    switch (type) {
      case MARKER_TYPES.SERVICE:
        return <ServicePartnerMarker className="marker-icon" />;
      case MARKER_TYPES.CAR:
        return <CarMarker className="marker-icon" />;
      case MARKER_TYPES.VAN:
      default:
        return <VanMarker className="marker-icon" />;
    }
  };

  const validVehicles = useMemo(() => vehicles.filter((v) => isValidLatLng(v.latitude, v.longitude)), [vehicles]);

  const validStations = useMemo(() => stations.filter((s) => isValidLatLng(s.latitude, s.longitude)), [stations]);

  const validServicePartners = useMemo(
    () => servicePartners?.filter((sp) => isValidLatLng(sp.address.lat, sp.address.lng)) || [],
    [servicePartners]
  );

  const renderVehicles = useMemo(
    () =>
      validVehicles.map((marker, index) => (
        <Marker
          key={`vehicle-${marker.vehicleType}-${index}`}
          marker={marker}
          brand={marker.brand}
          price={marker.price}
          type={marker.vehicleClass}
          hoverMarker={settings.hoverMarker}
          carsCount={marker.carsCount}
          priceLevel={marker.priceLevel}
          speed={marker.speed}
          lat={marker.latitude}
          lng={marker.longitude}
          started={marker.isCarDriving}
          customerCity={marker.city}
          customerPlace={marker.place}
          name={`${marker.driver?.name || '-'} ${marker.driver?.surname || '-'}`}
          fuelLevel={marker['fuel-level']}
          licensePlate={marker['license-plate']}
          userCar={`${marker.manufacturer} ${marker.vehicleModelType}`}
          markerIcon={renderMarkerIcon(marker.vehicleClass)}
          onMarkerClick={() => onMarkerClick('vehicles', marker.id, marker)}
        />
      )),
    [validVehicles, settings.hoverMarker, onMarkerClick]
  );

  const renderStations = useMemo(
    () =>
      validStations.map((marker, index) => (
        <Marker
          key={`station-${marker.type}-${index}`}
          marker={marker}
          type={marker.type}
          hoverMarkerStation={settings.hoverMarkerStation}
          brand={marker.brand}
          lat={marker.latitude}
          lng={marker.longitude}
          price={marker[`price-${settings?.typeFuel || 'diesel'}`]}
          onMarkerClick={() => onMarkerClick('station', marker.id, marker)}
          priceLevel={priceLevel(index, stations.length)}
        />
      )),
    [validStations, settings.hoverMarkerStation, onMarkerClick, settings.typeFuel, stations.length]
  );

  const renderServicePartners = useMemo(
    () =>
      validServicePartners.map((marker, index) => (
        <Marker
          key={`service-${marker.type}-${index}`}
          marker={marker}
          type={marker.type}
          hoverMarkerService={settings.hoverMarkerService}
          name={marker.title}
          rating={+marker.rating}
          lat={marker.address.lat}
          lng={marker.address.lng}
          customerPlace={marker.address}
          markerIcon={renderMarkerIcon(marker.type)}
          onMarkerClick={() => onMarkerClick('service', marker.id, marker)}
        />
      )),
    [validServicePartners, settings.hoverMarkerService, onMarkerClick]
  );

  const defineCenter = useCallback(() => {
    const { lat, lng } = settings.coordinates || {};
    return isValidLatLng(lat, lng) ? { lat: +lat, lng: +lng } : defaultCenter;
  }, [settings.coordinates, defaultCenter]);

  const defineZoom = useCallback(() => {
    const { marker, isStation, hoverMarker, hoverMarkerStation, hoverMarkerService, selectAppointment } = settings;

    if (isStation) return currentZoom;

    if (marker || hoverMarker || hoverMarkerStation || hoverMarkerService || selectAppointment) {
      return 14;
    }
    return currentZoom;
  }, [settings, currentZoom]);

  const onMapPositionChange = (properties) => {
    setCurrentZoom(properties.zoom);
    onMapBoundsChange(properties);
  };

  const fitMapToBounds = (directionResults) => {
    if (!mapState?.map || !directionResults || !directionResults.length) return;
    const bounds = new window.google.maps.LatLngBounds();

    directionResults.forEach((directionResult) => {
      directionResult.routes.forEach((route) => {
        if (route.bounds) {
          bounds.union(route.bounds);
        }
      });
    });

    mapState.map.fitBounds(bounds);
  };

  const getNextDirections = (waypoints, startingIndex) => {
    const returnPoints = [];
    if (startingIndex > waypoints.length - 1) {
      return [returnPoints, null];
    }

    let endIndex = startingIndex + MAX_WAYPOINTS_PER_REQUEST;

    endIndex += 2;

    if (endIndex > waypoints.length - 1) {
      endIndex = waypoints.length;
    }

    for (let i = startingIndex; i < endIndex; i++) {
      returnPoints.push(waypoints[i]);
    }

    if (endIndex !== waypoints.length) {
      return [returnPoints, endIndex - 1];
    } else {
      return [returnPoints, null];
    }
  };

  const loadDirections = (waypoints, callbackFunc, waypointIndex = 0, path = []) => {
    setMapLoaderVisible(true);

    const [points, nextWaypointIndex] = getNextDirections(waypoints, waypointIndex);
    if (!points.length) {
      callbackFunc([]);
      return;
    }

    const startl = points.shift().location;
    const endl = points.pop().location;
    const request = {
      origin: startl,
      destination: endl,
      waypoints: points,
      travelMode: window.google.maps.TravelMode.DRIVING,
      optimizeWaypoints: false,
      provideRouteAlternatives: false
    };

    const service = new window.google.maps.DirectionsService();
    service.route(request, (response, status) => {
      if (status === 'OK') {
        path = path.concat(response);

        if (nextWaypointIndex != null) {
          setTimeout(() => {
            loadDirections(waypoints, callbackFunc, nextWaypointIndex, path);
          }, 100);
        } else {
          // All segments fetched
          callbackFunc(path);
          setMapLoaderVisible(false);
        }
      } else if (status === 'OVER_QUERY_LIMIT') {
        // Retry after slight delay
        setTimeout(() => {
          loadDirections(waypoints, callbackFunc, nextWaypointIndex, path);
        }, 800);
      } else {
        console.error(`Directions request failed due to ${status}`);
        setMapLoaderVisible(false);
      }
    });
  };

  const loadAdvancedMarkerLib = async () => {
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    return { AdvancedMarkerElement };
  };

  const drawDirections = (waypoints) => {
    if (!mapState?.map || !waypoints || !waypoints.length) return;

    const mappedDirections = waypoints.map((p) => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true
    }));

    loadDirections(mappedDirections, (path) => {
      // path is an array of direction responses
      path.forEach((direction) => {
        const directionsRenderer = new window.google.maps.DirectionsRenderer({
          suppressMarkers: true,
          preserveViewport: true,
          polylineOptions: {
            strokeColor: 'rgba(0,153,255,0.66)',
            strokeWeight: 4,
            icons: [
              {
                icon: lineSymbol,
                offset: '150px',
                repeat: '400px'
              }
            ]
          }
        });

        directionsRenderer.setMap(mapState?.map);
        directionsRenderer.setDirections(direction);
        ref.current.push(directionsRenderer);
      });

      // Place a start marker on the first waypoint
      if (mappedDirections.length > 0) {
        const createStartMarker = async (origin) => {
          const { AdvancedMarkerElement } = await loadAdvancedMarkerLib();

          const pin = new google.maps.marker.PinElement({
            background: '#61a951',
            borderColor: 'black',
            glyphColor: 'black'
          });

          startMarkerRef.current = new AdvancedMarkerElement({
            position: origin,
            map: mapState?.map,
            title: 'Start Point',
            content: pin.element
          });
        };

        const origin = mappedDirections[0].location;
        createStartMarker(origin);
      }

      fitMapToBounds(path);
    });
  };

  /**
   * Gather all waypoints from the trip.
   */
  const getAllTripWaypoints = (trip) => {
    const waypoints = [];
    trip?.tracks?.forEach((track) => {
      // add starting position
      if (track['starting-position']) {
        waypoints.push(track['starting-position']);
      }

      // add intermediate waypoints
      track?.waypoints?.forEach((w) => {
        if (w && w.latitude && w.longitude) {
          waypoints.push(w);
        }
      });

      // add stop position
      if (track['stop-position']) {
        waypoints.push(track['stop-position']);
      }
    });
    return waypoints;
  };

  const drawTrip = () => {
    if (trip?.tracks && activeVehicle === trip?.vehicleId) {
      const allWaypoints = getAllTripWaypoints(trip);
      // Draw directions from all the waypoints using the new chunking logic
      drawDirections(allWaypoints);
    }
  };

  const removeTrip = () => {
    startMarkerRef.current?.setMap(null);
    startMarkerRef.current = null;
    if (ref.current && ref.current.length) {
      ref.current.forEach((el) => el.setMap(null));
      ref.current = [];
    }
  };

  useEffect(() => {
    if (!mapState) return;
    if (activeVehicle === trip?.vehicleId) {
      removeTrip();
      drawTrip();
    } else {
      removeTrip();
    }
  }, [trip, mapState, activeVehicle]);

  /**
   * returns map Loader
   * @returns {JSX.Element}
   */
  const mapLoader = () => {
    return (
      <div className={'map-loader'}>
        <div className="list-loader">
          <TailSpin color="#FFF" height={50} width={50} />
        </div>
      </div>
    );
  };

  return (
    <>
      {showLoader && mapLoader()}
      <GoogleMapReact
        zoom={defineZoom()}
        center={defineCenter()}
        defaultZoom={defaultZoom}
        options={(maps) => ({
          mapTypeId: settings.satellite ? maps?.MapTypeId?.SATELLITE : maps?.MapTypeId?.ROADMAP,
          mapTypeControlOptions: {
            mapTypeIds: [maps?.MapTypeId?.ROADMAP, maps?.MapTypeId?.SATELLITE, maps?.MapTypeId?.HYBRID]
          },
          mapId: process.env.REACT_APP_GOOGLE_MAP_ID
        })}
        defaultCenter={defaultCenter}
        onChange={onMapPositionChange}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY, libraries: 'marker' }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => setMapState({ map: map, maps: maps })}>
        {renderVehicles}
        {renderStations}
        {renderServicePartners}
      </GoogleMapReact>
    </>
  );
};

export default Map;
