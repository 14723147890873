import React from 'react';

const ProfileImageUpload = ({ name = 'file', allowFileType = 'image/png, image/jpeg', onChange = () => {} }) => {
  return (
    <div className={'fileInput'}>
      <input className={'file-upload'} name={name} type="file" accept={allowFileType} onChange={onChange} />
    </div>
  );
};

export default ProfileImageUpload;
