import React from 'react';

/** Load translation helper **/
import { t } from '@helpers/i18n';

/** load styles **/
import './NoResult.styles.scss';

const NoResultsPopup = () => {
  return (
    <div className={'empty-list-card'}>
      <p className="message">{t('no_data_is_available')}</p>
    </div>
  );
};

export default NoResultsPopup;
