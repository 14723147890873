import React, { useState } from 'react';
import SimpleCard from '@components/Card/SimpleCard';
import Avatar from '@components/User/Avatar';
import { dateFormatted } from '@services/formatters';
import { extractTime, toHoursMinSec } from '@helpers/common/tracking';
import classnames from 'classnames';
import { t } from '@helpers/i18n';

const TimeTrackingCard = ({ tracking, onTrackingClick }) => {
  const { date, avatar, name, surname, begin, end, seconds, inProgress } = tracking;
  const [selectedCard, setSelectedCard] = useState(false);

  const getTrackedTime = (begin, end, inProgress) => (
    <>{inProgress ? extractTime(begin) : `${extractTime(begin)} - ${extractTime(end)}`}</>
  );

  const renderLeft = () => <Avatar src={avatar} iconBackgroundColor={'transparent'} small />;

  const renderPrimaryContent = () => (
    <div>
      <p>{name}</p>
      <p>{surname}</p>
    </div>
  );

  const renderRight = () => (
    <>
      <p>{inProgress ? t('tracking_in_progress') : dateFormatted(date)}</p>
      <p>{getTrackedTime(begin, end, inProgress)}</p>
      <p>{toHoursMinSec(seconds)}</p>
    </>
  );

  return (
    <article
      className={classnames('invoice-card', {
        'invoice-status-green': !inProgress,
        'invoice-status-yellow': inProgress
      })}>
      <SimpleCard
        primaryContent={renderPrimaryContent}
        renderLeft={renderLeft}
        renderRight={renderRight}
        onClick={() => onTrackingClick(date, setSelectedCard, tracking.driverId)}
        className={selectedCard ? 'light-blue-card' : ''}
      />
    </article>
  );
};

export default React.memo(TimeTrackingCard);
