import React, { useCallback } from 'react';

/** Load components **/
import LazyLoadingList from '@components/LazyLoadingList';
import AppointmentCard from '@components/Appointment/Card';

const AppointmentCardList = (props) => {
  const {
    loading = false,
    appointments = [],
    isLastPage = false,
    isEndReached = () => {},
    resetAppointmentsList
  } = props;

  /**
   * Render single Appointment card
   * @param {object} appointment - appointment object
   * @param {number} i - index of element
   * @returns {JSX.Element}
   **/
  const renderAppointmentsCard = (appointment, i) => (
    <AppointmentCard appointment={appointment} resetAppointmentsList={resetAppointmentsList} key={i} />
  );

  /**
   * Render Lazy Loading Card list
   * @returns {JSX.Element}
   **/
  const renderContent = useCallback(() => {
    return (
      <LazyLoadingList
        data={appointments || []}
        loading={loading}
        isLastPage={isLastPage}
        onEndReached={isEndReached}
        renderItem={renderAppointmentsCard}
      />
    );
  }, [loading, appointments]);

  return <>{renderContent()}</>;
};

export default AppointmentCardList;
