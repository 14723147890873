import React from 'react';
import Select from 'react-select';

import './index.scss';
import classnames from 'classnames';

export const INPUT_TYPES = {
  TEXT: 'text',
  TEXT_AREA: 'textArea',
  NUMBER: 'number',
  DATE: 'date',
  SELECT: 'select',
  TEL: 'tel'
};

export const INPUT_SIZES = {
  ONE_FIFTH: 'one-fifth',
  FOUR_FIFTH: 'four-fifth',
  QUARTER: 'quarter',
  ONE_THIRD: 'one-third',
  HALF: 'half',
  TWO_THIRD: 'two-thirds',
  THREE_QUARTERS: 'three-quarters',
  FULL: 'full',
  MARGIN_LEFT: 'margin-left'
};

export const Input = (props) => {
  const {
    type,
    inputType = 'text',
    placeholder = '',
    label,
    field = null,
    options = [],
    width = INPUT_SIZES.FULL,
    ...prop
  } = props;
  const renderInput = () => {
    // const dot = (color = '#ccc') => ({
    //   alignItems: 'center',
    //   display: 'flex',

    //   ':before': {
    //     backgroundColor: color,
    //     borderRadius: 10,
    //     content: '" "',
    //     display: 'block',
    //     marginRight: 8,
    //     height: 10,
    //     width: 10
    //   }
    // });

    if (type === INPUT_TYPES.SELECT) {
      return (
        <Select
          className={'select-box'}
          classNamePrefix="select"
          isDisabled={false}
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          placeholder={placeholder}
          name={name}
          options={options}
        />
      );
    } else if (type === INPUT_TYPES.TEXT_AREA) {
      return <textarea placeholder={placeholder} {...field} {...prop} />;
    }

    return <input placeholder={placeholder} type={inputType} {...field} {...prop} />;
  };

  return (
    <div className={classnames('input', width, props.marginLeft)}>
      <p className="input-label">{label}</p>
      {renderInput()}
      {props.error}
    </div>
  );
};
