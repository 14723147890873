import React, { useMemo } from 'react';
import { DEFAULT_DATE_ZERO, NO_DATE } from '@constants';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import DataForm from '@components/Common/Form/DataForm';
import { Button } from '@components/Common/Button';
import { EmployeeLicenseImages } from '@components/Employee';
import { BUTTON_TYPES } from '@constants';
import { t, date } from '@helpers/i18n';
import { LicenseIcon } from '@services/formatters/profile/license';
import { getLocalizedCountryLabel } from '@services/utils/Profile.utils';
import { useSelector } from 'react-redux';

const PersonalId = ({ profile = {}, auth = {}, onEditClick = () => {}, onPreviewImage = () => {} }) => {
  const { personalId } = useSelector((state) => state.profile);
  const language = profile?.language ? profile.language : 'en-GB';

  const localizedCountryName = useMemo(
    () => getLocalizedCountryLabel(personalId.nationality, language),
    [personalId.nationality, language]
  );

  /** Renders Edit License button **/
  const renderEditButton = () => {
    return (
      profile.id === auth.info.id && (
        <Button
          type={BUTTON_TYPES.WARNING}
          icon={<CardAccountDetailsOutlineIcon />}
          text={t('update-personal-id')}
          className={'self-center'}
          onClick={onEditClick}
        />
      )
    );
  };

  /** Driving license form data **/
  const driversLicenseData = [
    {
      color: personalId?.state ?? '',
      name: t('state'),
      value: personalId.state,
      icon: LicenseIcon.getIcon(personalId.state)
    },
    {
      name: t('surname'),
      value: personalId.last_name ? personalId?.last_name : '-'
    },
    {
      name: t('name'),
      value: personalId.first_name ? personalId.first_name : '-'
    },
    {
      name: t('number'),
      value: personalId.documentIdentifier ? personalId.documentIdentifier : '-'
    },
    {
      name: t('date-of-issue'),
      value:
        personalId.issueDate && personalId.issueDate !== NO_DATE && personalId.issueDate !== DEFAULT_DATE_ZERO
          ? date(personalId.issueDate)
          : '-'
    },
    {
      name: t('expiry-date'),
      value:
        personalId.expirationDate &&
        personalId.expirationDate !== NO_DATE &&
        personalId.expirationDate !== DEFAULT_DATE_ZERO
          ? date(personalId.expirationDate)
          : '-'
    },
    {
      name: t('birthday'),
      value: date(profile.birthday) && date(profile.birthday) !== NO_DATE ? date(profile.birthday) : '-'
    },
    {
      name: t('nationality'),
      value: localizedCountryName ?? '-'
    }
  ];

  return (
    <div className={'flex-1'}>
      <div className={'license-details details-content flex flex-col justify-between p-b-2'}>
        <EmployeeLicenseImages driverLicense={personalId} onImagePreview={onPreviewImage} />
        <DataForm data={driversLicenseData} />
        {renderEditButton()}
      </div>
    </div>
  );
};

export default PersonalId;
