import React from 'react';
import SimpleCard from '@components/Card/SimpleCard';

const VehicleExpandableCard = (props) => {
  const {
    expanded,
    onVehicleClick,
    primaryContent,
    rightContent,
    leftContent,
    vehicleCardColor,
    additionalClass,
    children
  } = props;

  return (
    <SimpleCard
      onClick={onVehicleClick}
      className={`damage-card ${vehicleCardColor()} ${additionalClass}`}
      renderRight={rightContent}
      renderLeft={leftContent}
      primaryContent={primaryContent}>
      {expanded && children}
    </SimpleCard>
  );
};

export default VehicleExpandableCard;
