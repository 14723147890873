import React from 'react';
import classNames from 'classnames';
import Icon from './Icon.component';

import PersonIcon from 'mdi-react/AccountIcon';

import './Avatar.styles.scss';

const Avatar = ({
  micro,
  small = false,
  src = '',
  className,
  hasImage = true,
  missingIcon = <PersonIcon />,
  icon = null,
  iconBackgroundColor = null,
  children
}) => {
  const renderImg = () => {
    if (src) {
      if (src.startsWith('http')) {
        return <img src={src} />;
      }

      return <img src={`data:image/png;base64, ${src}`} />;
    }
    return missingIcon;
  };

  return (
    <div className={classNames('user-avatar', { [className]: !!className })}>
      <div className={classNames('img-container', { small: small }, { micro: micro })}>{hasImage && renderImg()}</div>
      {icon && <Icon iconBackgroundColor={iconBackgroundColor} small={small} icon={icon} />}
      {children}
    </div>
  );
};

export default Avatar;
