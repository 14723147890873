import React from 'react';

/** Load css styles **/
import './SimpleCard.styles.scss';

const SimpleCard = ({
  renderRight = () => {},
  primaryContent = () => {},
  renderLeft = () => {},
  children,
  onClick = () => {},
  className = ''
}) => {
  return (
    <div className={`card ${className}`}>
      <div className={'card-inner'}>
        <div className="top-section" onClick={onClick}>
          <div className="left-section">
            {renderLeft()}
            <div className="text-container">{primaryContent()}</div>
          </div>
          <div className="right-section">{renderRight()}</div>
        </div>
        <div className={'bottom-section'}>{children}</div>
      </div>
    </div>
  );
};

export default SimpleCard;
