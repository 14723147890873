import React from 'react';

import './style.scss';

const DamageImage = ({ imgSrc = '', alt = '', onImageClick = () => {} }) => {
  return (
    <div className={'dmg-img'} onClick={onImageClick}>
      <img src={`data:image/png;base64, ${imgSrc}`} alt={alt} />
    </div>
  );
};

export default DamageImage;
