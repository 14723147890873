import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
/** load components **/
import AppointmentCalendar from '@components/AppointmentCalendar';
import ButtonGroup from '@components/Common/ButtonGroup';
/** load icons **/
import Calendar from 'mdi-react/CalendarMonthOutlineIcon';
import CalendarBlankOutlineIcon from 'mdi-react/CalendarBlankOutlineIcon';
/** load services **/
import { format } from 'date-fns';
import { t } from '@helpers/i18n';

import * as appointmentsActions from '@store/appointments/actions';

import './AppointmentCalendar.styles.scss';

const defaultSelected = {
  from: null,
  to: null
};

const AppointmentCalendarContainer = ({ filterAppointmentsByDates = () => {}, reportAppointment }) => {
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.auth);
  const { calendarAppointments } = useSelector((state) => state.appointments);
  const [range, setRange] = useState(defaultSelected);
  const [showCalendarSidebar, setShowCalendarSidebar] = useState(true);

  useEffect(() => {
    filterAppointmentsByDates(range);
  }, [range]);

  useEffect(() => {
    const date = new Date();
    const dateObj = new Date(date.getFullYear(), date.getMonth(), 1);
    const monthStartDate = format(dateObj, 'yyyy-MM-dd');
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const d = new Date(year, month, 0);
    const monthEndDate = format(d, 'yyyy-MM-dd');
    dispatch(
      appointmentsActions.calendarAppointmentsGet({
        userId: auth.user.id,
        search: '',
        filter: '',
        page: null,
        dateFrom: `&filterDateFrom=${monthStartDate}`,
        dateTo: `&filterDateTo=${monthEndDate}`
      })
    );
  }, []);

  /**
   * Create appointment action
   **/
  const onMonthChange = (startDate) => {
    const monthStartDate = format(startDate, 'yyyy-MM-dd');
    const dateObj = new Date(startDate);
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    const d = new Date(year, month, 0);
    const monthEndDate = format(d, 'yyyy-MM-dd');
    dispatch(
      appointmentsActions.calendarAppointmentsGet({
        userId: auth.user.id,
        search: '',
        filter: '',
        page: null,
        dateFrom: `&filterDateFrom=${monthStartDate}`,
        dateTo: `&filterDateTo=${monthEndDate}`
      })
    );
  };

  /**
   * On Date Change handler
   * @param {object|null} dates - date object from calendar component
   * @parm {string} dates.from - date from
   * @param{string} dates.to - date to param
   **/
  const onDateChange = (dates) => {
    if (!dates) {
      setRange(dates);
    } else {
      if (!dates.to) {
        setRange({
          from: dates.from,
          to: dates.from
        });
      } else {
        setRange(dates);
      }
    }
  };

  /**
   * Toggle Calendar sidebar visibility
   **/
  const toggleSidebar = () => {
    setShowCalendarSidebar(!showCalendarSidebar);
  };

  const buttonGroup = [
    {
      label: t('request_appointment'),
      icon: <Calendar />,
      action: () => reportAppointment(true)
    },
    {
      label: t('report_appointment'),
      icon: <CalendarBlankOutlineIcon />,
      action: () => reportAppointment(false)
    }
  ];

  return (
    <div className={`appointment-calendar ${showCalendarSidebar ? 'calendar-expand' : ''}`}>
      <div className={'form-cont'}>
        <AppointmentCalendar
          range={range}
          setRange={onDateChange}
          appointments={calendarAppointments}
          onMonthChange={onMonthChange}
          toggleSidebar={toggleSidebar}
        />
        <ButtonGroup className={'appointment-btn-group'} buttonGroup={buttonGroup} />
      </div>
    </div>
  );
};

export default AppointmentCalendarContainer;
