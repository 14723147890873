import countriesBG from '@assets/countries/countries_bg-BG.json';
import countriesDE from '@assets/countries/countries_de-DE.json';
import countriesGB from '@assets/countries/countries_en-GB.json';
import countriesPL from '@assets/countries/countries_pl-PL.json';
import countriesRO from '@assets/countries/countries_ro-RO.json';
import countriesTR from '@assets/countries/countries_tr-TR.json';
// Language to country data mapping
const countryMap = {
  'de-DE': countriesDE,
  'en-GB': countriesGB,
  'ro-RO': countriesRO,
  'bg-BG': countriesBG,
  'tr-TR': countriesTR,
  'pl-PL': countriesPL
};

// Function to get localized country label based on value and appLanguage
export function getLocalizedCountryLabel(value, appLanguage) {
  // Get the country data for the specified language, default to English if not found
  const countryData = countryMap[appLanguage] || countriesGB;

  // Find the country with the matching value
  const country = countryData.find((item) => item.value === value);

  // Return the localized label or the original value if not found
  return country ? country.label : value;
}
