import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from '@mui/material/Grid';
import { de, enGB, bg, pl, ro, tr } from 'date-fns/locale';
import { DATE_FORMAT_DMY_DOT_AND_TIME } from '@services/formatters/util/DateTime.formatters';
import { t } from '@helpers/i18n';

const LOCALE_MAP = {
  'de-DE': de,
  'en-GB': enGB,
  'bg-BG': bg,
  'pl-PL': pl,
  'ro-RO': ro,
  'tr-TR': tr
};

/**
 * DateTimePickerGrid component.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.label - The label for the date time picker.
 * @param {string|Date} [props.value] - The current value of the date time picker.
 * @param {Function} props.onChange - The function to call when the value changes.
 * @param {string} [props.error] - Any error message to display.
 */
const DateTimePickerGrid = ({ label, value = null, onChange, error = '', language = 'en-GB' }) => {
  const dateLocale = LOCALE_MAP[language] || enGB;

  return (
    <Grid item xs={12}>
      <p className="input-label">{label}</p>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
        <div className={'preferred_date'}>
          <DateTimePicker ampm={false} format={DATE_FORMAT_DMY_DOT_AND_TIME} value={value} onChange={onChange} />
        </div>
      </LocalizationProvider>
      {error && <p className={'error-message-loc'}>{t(error)}</p>}
    </Grid>
  );
};

export default DateTimePickerGrid;
