import React from 'react';

const CarCheckPrimaryContent = ({ name = '', surname = '' }) => {
  return (
    <div className="primary-text">
      <p>{name} </p>
      <p>{surname} </p>
    </div>
  );
};

export default CarCheckPrimaryContent;
