import React from 'react';

/** Load components **/
import NoDamagesContainer from './NoDamagesContainer';
import DamageListContainer from './DamageListContainer';

/** load styles **/
import './style.scss';

const DamagePhotos = ({ damages = [] }) => {
  return (
    <div className={'damage-row'}>
      {damages.length > 0 ? <DamageListContainer damages={damages} /> : <NoDamagesContainer />}
    </div>
  );
};

export default DamagePhotos;
