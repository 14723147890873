import { format } from 'date-fns';

/** Car Check Filter states  **/
export const FILTER_STATES = ['', 'grey', 'green', 'red', 'yellow_warn', 'red_cross', 'red_archive', ''];

/** Helper for Car check on Change filter
 * @param {array} filters - filter options array
 * @param {MouseEvent} e
 **/
export const onFilterChange = (e, filters) => {
  const { id, checked } = e.currentTarget;
  let updateFilters = [...filters];
  const filterIndex = filters.findIndex((filter) => filter.id === parseInt(id));
  if (filterIndex === 0) {
    updateFilters = filters.map((filter) => ({ ...filter, value: checked }));
  } else {
    updateFilters[filterIndex].value = checked;
    const dd = updateFilters.filter((item, ind) => ind !== 0 && ind !== 7);
    const allTrue = dd.every((filter) => filter.value);
    updateFilters[0].value = allTrue;
  }
  return updateFilters;
};

/** Helper for Car Check list query
 * formats filters, search into query
 * @param {array} filters filter options array
 * @param {date} startDate - startDate only active when between filter active
 * @param {date} endDate - endDate only active when between filter active
 * @param {string} searchText - search text
 * **/
export const carCheckQueryFormatter = (filters, startDate, endDate, searchText = '') => {
  let query = ``;
  if (filters[0].value) {
    if (searchText) {
      query = query.concat(`&search=${searchText}`);
    }
    if (startDate) {
      query = query.concat(`&filterCreationDateFrom=${format(startDate, 'yyyy-MM-dd')}`);
    }
    if (endDate) {
      query = query.concat(`&filterCreationDateTo=${format(endDate, 'yyyy-MM-dd')}`);
    }
    return query;
  }
  filters.map((filter, index) => {
    if (filter.value && index < 7) {
      query = query.concat(`&filterState[]=${FILTER_STATES[index]}`);
    }
  });
  // when between filter active add startDate & endDate as parameters
  if (filters[7].value) {
    if (startDate) {
      query = query.concat(`&filterCreationDateFrom=${format(startDate, 'yyyy-MM-dd')}`);
    }
    if (endDate) {
      query = query.concat(`&filterCreationDateTo=${format(endDate, 'yyyy-MM-dd')}`);
    }
  }
  if (searchText) {
    query = query.concat(`&search=${searchText}`);
  }
  return query;
};
