import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import * as profileActions from '@store/profile/actions';

/** load components **/
import AlertCmp from '@components/Common/Alert';
// import AnimatedLoader from '@components/Common/AnimatedLoader';
import Slider from '@components/Common/Slider';
import PersonalId from './PersonalId';
import EditPersonalId from './EditPersonalId';

/** load services **/
import { LicenseFormatter } from '@services/formatters/profile/license';
import { t } from '@helpers/i18n';
import { media } from '@api/media';

import './PersonalIdTab.styles.scss';
import Loader from '@components/Common/Loader';

const PersonalIdTab = () => {
  const dispatch = useDispatch();
  const { profile, license, loading, personalId } = useSelector((state) => state.profile);
  const { auth } = useSelector((state) => state.auth);

  const sliderRef = useRef();

  const [slides, setSlides] = useState(LicenseFormatter.licenseImagesSlideFormatter(personalId));

  const [editMode, setEditMode] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);

  /** update driver ID details **/
  const updateDriverId = async (driverIdData) => {
    const [frontImg, backImg] = await LicenseFormatter.LicenseImagesFormatter(personalId, driverIdData);
    dispatch(
      profileActions.updatePersonalId({
        userId: auth.user.id,
        payload: {
          documentIdentifier: driverIdData.documentIdentifier,
          issueDate: driverIdData.issueDate,
          expirationDate: driverIdData.expirationDate,
          birthday: dayjs(driverIdData.birthday).format('YYYY-MM-DD'),
          first_name: driverIdData.name,
          last_name: driverIdData.surname,
          nationality: driverIdData.nationality.value,
          frontImage_blob: frontImg,
          backImage_blob: backImg
        }
      })
    );
    setEditMode(false);
  };

  const onSaveClick = (userData) => {
    updateDriverId(userData);
    setConfirmPopup(true);
    setSlides(LicenseFormatter.licenseImagesSlideFormatter(personalId));
  };

  /** preview license front or back image **/
  const onPreviewImage = (index) => {
    sliderRef.current.previewImage(index);
  };

  const loadFullImage = (index) => {
    const imageId = index ? personalId.backImage.desc : personalId.frontImage.desc;
    return media.getFullImage({ id: imageId, name: '', type: 'driverid', category: '' });
  };

  const onEditClick = () => {
    setEditMode(true);
  };

  const renderLoading = () => (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1
      }}>
      <Loader />
    </div>
  );

  const closePopup = () => {
    setConfirmPopup(false);
  };

  const personalIdProps = { profile, auth, license, loading, onEditClick, onPreviewImage };
  const editPersonalIdProps = { profile, license, loading, onSaveClick, onPreviewImage };

  useEffect(() => {
    dispatch(profileActions.getPersonalId({ userId: auth.user.id }));
  }, []);

  return (
    <>
      {loading && renderLoading()}
      {!loading && !editMode && profile && <PersonalId {...personalIdProps} />}
      {!loading && editMode && <EditPersonalId {...editPersonalIdProps} />}
      <AlertCmp visible={confirmPopup} title={t('profile_updated')} onClick={closePopup} />
      <Slider ref={sliderRef} slides={slides} setSlides={setSlides} imageAPI={loadFullImage} />
    </>
  );
};

export default PersonalIdTab;
