import React, { useState } from 'react';
import { t } from '@helpers/i18n';
/** Store */
import { useDispatch, useSelector } from 'react-redux';
import * as vehiclesActions from '@store/vehicles/actions';

/** load components */
import TagFormContainer from './TagsFormContainer/TagsFormContainer.component';
import { BUTTON_TYPES } from '@constants';
import { Button } from '@components/Common/Button';
import CheckIcon from 'mdi-react/CheckIcon';
import { toast } from 'react-toastify';

import { Api } from '@api';

const TagsFormComponent = ({ vehicleId, tags }) => {
  const { auth } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [vehicleTags, setVehicleTags] = useState(tags);

  /** Detect if tags list already containes element **/
  const containsElement = (item) => {
    if (item.id) {
      return vehicleTags.some((tag) => tag.name === item.name);
    } else {
      return vehicleTags.some((tag) => tag.name === item);
    }
  };

  const onAddTag = async (tag) => {
    if (!containsElement(tag)) {
      if (tag.id) {
        setVehicleTags(vehicleTags.concat(tag));
      } else {
        const { data } = await Api.vehicles.createUserTag(auth.user.id, [{ name: tag }]);
        setVehicleTags(vehicleTags.concat(...data));
      }
    } else {
      toast.error(t('tag-item-present-message'), {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }
  };

  const onTagRemove = (tag) => {
    const filteredTags = vehicleTags.filter((listTag) => listTag.id !== tag.id);
    setVehicleTags(filteredTags);
  };

  /** Save Vehicle Tags */
  const onSaveTags = () => {
    dispatch(
      vehiclesActions.saveVehiclesTagsRequest({
        vehicleId: vehicleId,
        tags: vehicleTags
      })
    );
    toast.success(t('tags-saved-msg'), {
      position: toast.POSITION.BOTTOM_CENTER
    });
  };

  return (
    <div className="details-content flex flex-col justify-between p-b-2">
      <div className={'tag-details'}>
        <h2 className={'edit-tags-title'}>{t('edit-tags-title')}</h2>
        <TagFormContainer tags={vehicleTags} onAddTag={onAddTag} onTagRemove={onTagRemove} />
      </div>
      <Button
        className={'save-tags'}
        icon={<CheckIcon />}
        isSmall
        onClick={onSaveTags}
        type={BUTTON_TYPES.SUCCESS}
        text={t('save')}
      />
    </div>
  );
};

export default TagsFormComponent;
