import React, { useEffect, useRef, useState, memo } from 'react';
import BlockingOverlayComponent from '@containers/Damages/AddDamage/BlockingOverlay/BlockingOverlay.component';
import { Field, Formik } from 'formik';
import { t } from '@helpers/i18n';
import { Input } from '@components/Common/Input';
import './VehicleInformation.style.scss';
import MultipleUploadComponent from '@components/Common/MultipleUpload';
import Close from 'mdi-react/CloseIcon';
import { mileageFormatter } from '@services/formatters';
import { LicenseDropdown } from '@components/Common/LicensePlate';
import Slider from '@components/Common/Slider';
import { onImageRemove, onImageUpload, toBase64 } from '@services/utils';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const VehicleInformationComponent = (props) => {
  const { stepData, onChange, isDisabled, profile, error } = props;

  const imgSliderRef = useRef();
  const imgPoliceSliderRef = useRef();

  const [slides, setSlides] = useState(new Array([]).fill(null));
  const [slidesPolice, setSlidesPolice] = useState(new Array([]).fill(null));

  /**
   * Gets damage types from translation files
   * @type {{label: string, id: string, value: string}[]}
   */
  const damageTypes = Object.entries(t('damage_types')).map(([key, value]) => ({ value, label: value, id: key }));
  const initialValue = {
    licensePlate: '',
    mileage: '',
    damageType: '',
    damageImages: [],
    policeImages: [],
    description: '',
    policeInvolved: '',
    policeInformation: ''
  };

  /**
   * Local state for all changes in the step.
   *
   * On mount should be set to global data from parent,
   * so the form can be prefilled with data when going in between steps
   */
  const [data, setData] = useState(stepData || initialValue);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  /**
   * Fetch vehicles for license plate picker
   */
  useEffect(() => {
    setVehicles(profile?.cars || []);
  }, []);

  /**
   * This effect should update parent state once there is local changes
   */
  useEffect(() => {
    onChange(data);
  }, [data]);

  const renderUploadedDamageImages = () => {
    return stepData.damageImages;
  };

  const renderUploadedPoliceImages = () => {
    return stepData.policeImages;
  };
  /**
   * Extract license plates from fetched vehicles
   * @returns {{label: *, id, value: *}[]}
   */
  const getLicencePlates = () => {
    const list = vehicles.map((vehicle, i) => ({
      licensePlate: vehicle.licencePlate,
      value: vehicle.licencePlate,
      id: i + 1
    }));
    list.unshift({ licensePlate: '__-__ ___', value: '__-__ ___', id: 0 });
    return list;
  };

  /**
   * Gets mileage of the selected vehicle
   * @param licencePlate - license plate of the selected vehicle
   * @returns {string|null}
   */
  const getVehicleMileage = (licencePlate) => {
    const selectedVehicle = vehicles.find((vehicle) => vehicle.licencePlate === licencePlate);
    if (selectedVehicle?.mileage) {
      const roundMileage = Math.round(selectedVehicle.mileage);
      return mileageFormatter(roundMileage.toString());
    }
    return 0;
  };

  /**
   *
   * @param {string} propertyName
   * @param value
   */
  const onFieldChange = (propertyName, value) => {
    if (propertyName === 'licensePlate') {
      // setData({... data, [propertyName]: value});
      setData({ ...data, [propertyName]: value, mileage: getVehicleMileage(value) });
      return;
    }
    if (propertyName === 'mileage') {
      setData({ ...data, [propertyName]: mileageFormatter(value) });
      return;
    }
    setData({ ...data, [propertyName]: value });
  };

  /**
   * Format mileage input
   * @param value
   */
  const validateMileage = (value) => {
    // test is input value number
    const numbersOnly = /^[0-9]*\.?[0-9]*$/;
    if (value.match(numbersOnly)) {
      let replacedValue = value.replace(/\s/g, '');
      replacedValue = replacedValue.replace('-', '');
      replacedValue = replacedValue.replace('.', '');
      replacedValue = replacedValue.replace(',', '');
      replacedValue = replacedValue.length <= 6 ? replacedValue : replacedValue.slice(0, 6);
      onFieldChange('mileage', mileageFormatter(replacedValue));
    } else {
      onFieldChange('mileage', mileageFormatter(data?.mileage));
    }
  };

  const itemListView = (option) => {
    return <p>{option.licensePlate}</p>;
  };

  /**
   * Initial slider call when user clicks on image
   * @param {number} index
   * @param imgSliderRef
   **/
  const onPreviewImage = (index, imgSliderRef) => {
    imgSliderRef.current.previewImage(index);
  };

  const loadFullImageDamage = async (index) => {
    const filteredImages = renderUploadedDamageImages();
    const image = await toBase64(filteredImages[index]);
    const commaIndex = image.indexOf(',');
    const parsedImg = image.slice(commaIndex + 1);
    return { data: { blob: parsedImg } };
  };

  const loadFullImagePolice = async (index) => {
    const filteredImages = renderUploadedPoliceImages();
    const image = await toBase64(filteredImages[index]);
    const commaIndex = image.indexOf(',');
    const parsedImg = image.slice(commaIndex + 1);
    return { data: { blob: parsedImg } };
  };

  return (
    <div className={'main-form-container'}>
      {/* Transparent overlay preventing changes when not on this step */}
      {isDisabled && <BlockingOverlayComponent />}

      <Formik
        enableReinitialize
        onSubmit={undefined}
        initialValues={{
          licensePlate: data.licensePlate,
          mileage: data.mileage,
          damageType: data.damageType,
          damageImages: data.damageImages,
          policeImages: data.policeImages,
          description: data.description,
          policeInvolved: data.policeInvolved,
          policeInformation: data.policeInformation
        }}>
        {({ values }) => {
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              <div style={{ marginTop: '15px' }}>
                <h3 className={'card-title'}>{t('vehicle_information')}</h3>

                <div style={{ marginBottom: '20px' }}>
                  <p className="input-label">{`${t('license-plate')} *`}</p>
                  <LicenseDropdown
                    license={values.licensePlate}
                    options={getLicencePlates() || []}
                    itemListView={itemListView}
                    licenseChange={(value) => onFieldChange('licensePlate', value)}
                    onOptionPress={(value) => {
                      onFieldChange('licensePlate', value.licensePlate);
                    }}
                  />
                  {error?.licensePlate && (
                    <p className={'error-message'} style={{ marginTop: '10px' }}>
                      {t(error?.licensePlate)}
                    </p>
                  )}
                </div>

                <Field
                  label={t('mileage')}
                  name="mileage"
                  component={Input}
                  className={'dark-blue-input'}
                  style={{ marginBottom: '20px', fontSize: '16px' }}
                  value={values.mileage}
                  onChange={(e) => validateMileage(e.target.value)}
                />
                {error?.mileage && <p className={'error-message'}>{t(error?.mileage)}</p>}

                <p className="input-label">{`${t('type_of_damage')} *`}</p>
                <Field
                  value={values.damageType}
                  className={'select-box dark-blue-input'}
                  style={{ marginBottom: '20px', maxWidth: '100%' }}
                  component={'select'}
                  name="damageType"
                  onChange={(e) => onFieldChange('damageType', e.target.value)}>
                  <option key={'empty'} value={null}>
                    -
                  </option>

                  {damageTypes.map((damageType) => (
                    <option key={damageType.id} value={damageType.value}>
                      {damageType.label}
                    </option>
                  ))}
                </Field>
                {error?.damageType && <p className={'error-message'}>{t(error?.damageType)}</p>}

                <Field
                  label={`${t('what_happened')} *`}
                  name="description"
                  component={Input}
                  type={'textArea'}
                  rows={5}
                  className={'dark-blue-input'}
                  style={{ marginBottom: '20px', fontSize: '16px' }}
                  value={values.description}
                  onChange={(e) => onFieldChange('description', e.target.value)}
                />
                {error?.description && <p className={'error-message'}>{t(error?.description)}</p>}

                {!!values?.damageImages?.length && (
                  <>
                    <p className="input-label">{`${t('photos_of_the_damage_length')} *`}</p>

                    <div className={'damage-image-list'}>
                      {renderUploadedDamageImages().map((file, index) => (
                        <div className={'image-container'} key={index.toString()}>
                          <img
                            alt={'damage-image'}
                            onClick={() => onPreviewImage(index, imgSliderRef)}
                            src={window.URL.createObjectURL(file)}
                          />
                          <span
                            className={'img-remove'}
                            onClick={() =>
                              onImageRemove(index, onFieldChange, stepData.damageImages, 'damageImages', setSlides)
                            }>
                            <Close />
                          </span>
                        </div>
                      ))}
                    </div>
                    {error?.damageImages && <p className={'error-message'}>{t(error?.damageImages)}</p>}
                  </>
                )}

                {/*<p className="input-label">{`${t("photos_of_the_damage_length")} *`}</p>*/}
                <MultipleUploadComponent
                  name={'damageImages'}
                  withoutPreview
                  value={values.damageImages || []}
                  onChange={(e) =>
                    onImageUpload(
                      e.target.files,
                      values.damageImages || [],
                      onFieldChange,
                      stepData.damageImages,
                      setSlides,
                      'damageImages'
                    )
                  }
                  onRemove={(index) => setRemovedFiles([...removedFiles, index])}
                />
                {error?.damageImages && (
                  <p className={'error-message'} style={{ marginTop: 0 }}>
                    {t(error?.damageImages)}
                  </p>
                )}

                <p className="input-label">{`${t('police_involved')} *`}</p>
                <RadioGroup
                  row
                  value={values.policeInvolved}
                  onChange={(event) => onFieldChange('policeInvolved', event.target.value)}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  style={{ paddingLeft: '10px', marginBottom: '20px' }}>
                  <FormControlLabel value="yes" control={<Radio />} label={t('yes')} />
                  <FormControlLabel value="no" control={<Radio />} label={t('no')} />
                </RadioGroup>
                {error?.policeInvolved && <p className={'error-message'}>{t(error?.policeInvolved)}</p>}

                {values.policeInvolved === 'yes' && (
                  <>
                    <Field
                      label={`${t('police_information')} *`}
                      name="description"
                      component={Input}
                      type={'textArea'}
                      rows={5}
                      className={'dark-blue-input'}
                      style={{ marginBottom: '20px' }}
                      value={values.policeInformation}
                      onChange={(e) => onFieldChange('policeInformation', e.target.value)}
                    />
                    {error?.policeInformation && <p className={'error-message'}>{t(error?.policeInformation)}</p>}

                    {!!values?.policeImages?.length && (
                      <>
                        {/*<p className="input-label">{`${t("photos_of_the_damage_length")} *`}</p>*/}

                        <div className={'damage-image-list'}>
                          {renderUploadedPoliceImages().map((file, index) => (
                            <div className={'image-container'} key={index.toString()}>
                              <img
                                alt={'damage-image'}
                                onClick={() => onPreviewImage(index, imgPoliceSliderRef)}
                                src={window.URL.createObjectURL(file)}
                              />
                              <span
                                className={'img-remove'}
                                onClick={() =>
                                  onImageRemove(
                                    index,
                                    onFieldChange,
                                    stepData.policeImages,
                                    'policeImages',
                                    setSlidesPolice
                                  )
                                }>
                                <Close />
                              </span>
                            </div>
                          ))}
                        </div>
                        {error?.policeImages && <p className={'error-message'}>{t(error?.policeImages)}</p>}
                      </>
                    )}

                    <MultipleUploadComponent
                      name={'policeImages'}
                      withoutPreview
                      value={values.policeImages || []}
                      onChange={(e) =>
                        onImageUpload(
                          e.target.files,
                          values.policeImages || [],
                          onFieldChange,
                          stepData?.policeImages,
                          setSlidesPolice,
                          'policeImages'
                        )
                      }
                      onRemove={(index) => setRemovedFiles([...removedFiles, index])}
                    />
                    {error?.policeImages && (
                      <p className={'error-message'} style={{ marginTop: 0 }}>
                        {t(error?.policeImages)}
                      </p>
                    )}
                  </>
                )}
              </div>
            </form>
          );
        }}
      </Formik>
      {<Slider ref={imgSliderRef} slides={slides} setSlides={setSlides} imageAPI={loadFullImageDamage} />}
      {
        <Slider
          ref={imgPoliceSliderRef}
          slides={slidesPolice}
          setSlides={setSlidesPolice}
          imageAPI={loadFullImagePolice}
        />
      }
    </div>
  );
};

export default memo(VehicleInformationComponent);
