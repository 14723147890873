import React from 'react';
import './DriverInfo.styles.scss';

const DriverInfo = ({ driverName = '', driverSurname = '', tags = [] }) => {
  return (
    <>
      <p>{`${driverName ?? ''} ${driverSurname ?? ''}`}</p>
      <p className={'tags-list'}>{tags.length ? tags.map((tag) => tag.name).join(' | ') : ''}</p>
    </>
  );
};

export default DriverInfo;
