import React from 'react';
import { dateFormatted } from '@services/formatters';
import { LicensePlate } from '@components/Car/LicensePlate';

const DamageInfo = ({ vehicleId = '', damageNumber = '', date = null }) => {
  return (
    <>
      <p>{dateFormatted(date)}</p>
      <p>{damageNumber}</p>
      <LicensePlate country="D" license={vehicleId} />
    </>
  );
};

export default DamageInfo;
