import React, { useState } from 'react';
import ReportingCardComponent from '@components/Reporting/ReportingCard/ReportingCard.component';
import Grid from '@mui/material/Grid';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './MileagePerDayOfTheWeek.styles.scss';

const MileagePerDayOfTheWeekComponent = ({
  chartColors = [],
  titleComponent = () => {},
  selectedVehicles = [],
  dataPerWeek = [],
  dataAveragePerWeek = [],
  getMonthLabel = () => {},
  month = {}
}) => {
  const tooltipText = 'Die Analyse auf Wochentage-Basis hilft Ihnen die Auslastung Ihrer Fahrzeuge zu verbessern.';

  const [tableFilter, setTableFilter] = useState('total');

  /**
   * Gives correct data according to the filter
   * @returns {*}
   */
  const getChartData = () => {
    return tableFilter === 'total' ? dataPerWeek : dataAveragePerWeek;
  };

  return (
    <Grid item xs={12} xl={6}>
      <ReportingCardComponent
        headerContentLeft={titleComponent('Ø-Km je Wochentag & Fahrzeug', tooltipText)}
        headerContentRight={titleComponent(getMonthLabel(month))}
        additionalClass={'chart-container'}>
        <ResponsiveContainer width="100%" height="90%">
          <BarChart
            margin={{
              top: 45,
              bottom: 15,
              right: 20
            }}
            data={getChartData()}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {selectedVehicles.map((veh, index) => (
              <Bar dataKey={veh} key={index.toString()} stackId={'s'} fill={chartColors[index % chartColors.length]} />
            ))}
          </BarChart>
        </ResponsiveContainer>
        <div className={'filter-buttons'}>
          <ToggleButtonGroup
            color="primary"
            value={tableFilter}
            exclusive
            onChange={(e, value) => setTableFilter(value || tableFilter)}>
            <ToggleButton value="total">Gesamt</ToggleButton>
            <ToggleButton value="perDay">Ø pro Tag</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </ReportingCardComponent>
    </Grid>
  );
};

export default MileagePerDayOfTheWeekComponent;
