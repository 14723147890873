import React from 'react';
import './index.scss';
import classnames from 'classnames';
const DEFAULT_LICENSE_PLATE = `-`;

export const LicensePlate = ({ country = '', license = '', onClick = () => {} }) => {
  return (
    <div className={classnames('license-plate', { clickable: onClick })} onClick={onClick}>
      <div className="country-container">{country}</div>
      <div className={classnames('license-container', { 'default-license': !license })}>
        {license || DEFAULT_LICENSE_PLATE}
      </div>
    </div>
  );
};
