import React from 'react';

/** load components **/
import DamageItem from './DamageItem';

const DamageListContainer = ({ damages = [] }) => {
  return damages.map((damage, index) => {
    return <DamageItem damage={damage} index={index} key={index} />;
  });
};

export default DamageListContainer;
