import React from 'react';

import FilterForm from './FilterForm';
import ApplyButton from '../../Common/Button/ApplyButton';
import { onFilterChange } from './helper';
import { t } from '@helpers/i18n';

import './style.scss';

const CarCheckFilter = ({
  filterValues = [],
  visible = false,
  filterChanged,
  dates,
  onDateChange,
  applyFilter = () => {}
}) => {
  const filterChange = (e) => {
    const updatedFilters = onFilterChange(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible && (
      <div className={'list-filter'}>
        {/** Filter form **/}
        <FilterForm filters={filterValues} onChange={filterChange} date={dates} onDateChange={onDateChange} />
        {/** Filter submit btn
         * todo switch with component
         **/}
        <ApplyButton onClick={applyFilter} text={t('apply_btn')}></ApplyButton>
      </div>
    )
  );
};

export default CarCheckFilter;
