import React, { useState } from 'react';

import { t } from '@helpers/i18n';
import './SplitButton.styles.scss';
import TagOutlineIcon from 'mdi-react/TagOutlineIcon';
import AccountArrowLeftIcon from 'mdi-react/AccountArrowLeftIcon';

// todo refactor into more reusable component
const SplitButton = ({ assignDriver, editTags, disableButton }) => {
  const [bar, setBar] = useState(false);

  const toggleBar = () => {
    setBar(!bar);
  };

  return (
    <div className={'split-cmp self-center'}>
      <div
        id="split-btn-popover"
        className={`split-btn-popover popover js-popover js-split-btn js-tab-focus ${bar ? 'popover--is-visible' : ''}`}
        role="dialog">
        <menu className="split-btn-popover__list">
          <li>
            <button className="reset split-btn-popover__item" onClick={editTags}>
              <svg className="icon icon--xs margin-right-xxs opacity-50%" aria-hidden="true" viewBox="0 0 12 12"></svg>
              <TagOutlineIcon />
              <span className={'button-text'}>{t('edit-tags')}</span>
            </button>
          </li>
        </menu>
      </div>
      <div className={`split-btn inline-flex `}>
        <button
          className={`btn btn--primary assign-btn ${bar ? 'bottom-button-visible-popover' : ''} ${disableButton ? 'disabled' : ''}`}
          onClick={assignDriver}
          disabled={disableButton}>
          <svg className="icon icon--xs margin-right-xxs opacity-50%" aria-hidden="true" viewBox="0 0 12 12"></svg>
          <AccountArrowLeftIcon />
          <span className={'button-text'}>{t('assign-driver')}</span>
        </button>

        <span className="split-btn__separator" aria-hidden="true" />

        <button
          className={`btn btn--primary padding-x-xs block split-arrow`}
          onClick={toggleBar}
          aria-controls="split-btn-popover">
          <svg className={`icon icon--xxs ${bar ? 'icon-rotate' : ''}`} viewBox="0 0 12 12">
            <title>Toggle other options</title>
            <polyline
              points="1 4 6 9 11 4"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SplitButton;
