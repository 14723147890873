import React from 'react';
import { ICON_BACKGROUND_COLORS } from '@helpers/constants';
import classnames from 'classnames';

const Icon = ({ iconBackgroundColor = '', icon = null, small = false }) => {
  return (
    <div
      className={classnames(`icon-container ${iconBackgroundColor}`, {
        small: small,
        'has-background-color': iconBackgroundColor !== ICON_BACKGROUND_COLORS.TRANSPARENT
      })}>
      {icon}
    </div>
  );
};

export default Icon;
