import React from 'react';
import EditPersonalIdComponent from '@components/User/EditPersonalId';
import { useSelector } from 'react-redux';

const EditPersonalId = ({ profile, loading, onSaveClick, onPreviewImage }) => {
  const { personalId } = useSelector((state) => state.profile);
  return (
    <div className={'flex-1'}>
      <EditPersonalIdComponent
        info={profile}
        license={personalId}
        loading={loading}
        onPreviewImage={onPreviewImage}
        onSave={(userData) => onSaveClick(userData)}
      />
    </div>
  );
};

export default EditPersonalId;
