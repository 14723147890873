import React, { useEffect, useState } from 'react';
/** load components **/
import SearchBarFilter from '@components/Common/SearchBar/withFilters';
import AppointmentFilter from '@components/Invoice/Filter/InvoiceFilter.component';
import InvoiceCardList from '@components/Invoice/InvoiceCardList/InvoiceCardList.component';

/** Load services **/
import { InvoicesQueryFormatter } from '@services/formatters/invoices/InvoicesFilter.formatter';

/** Filter States for Invoices **/
const filterInitialState = [
  { id: 0, filter: 'show_all', value: true },
  { id: 1, filter: 'monthly', value: true },
  { id: 2, filter: 'individual', value: true },
  { id: 3, filter: 'partly', value: true },
  { id: 4, filter: 'deposit', value: true },
  { id: 5, filter: 'cancellation', value: true },
  { id: 6, filter: 'between', value: true }
];

const InvoicesWrapper = ({
  invoices = [],
  loading = false,
  applyInvoicesFilter = () => {},
  endReached = () => {},
  lastPage = 0
}) => {
  const [invoicesSearch, setInvoicesSearch] = useState(''); // search field
  const [page, setPage] = useState(0); // current page
  const [showFilter, setShowFilter] = useState(false); // filter visibility
  const [settings, setSettings] = useState(filterInitialState); // filter values
  const [invoiceDates, setInvoiceDates] = useState({
    dateFrom: null,
    dateTo: null
  });

  useEffect(() => {
    // initial fetch
    applyInvoicesFilter({
      page: page,
      filter: '',
      dateFrom: null,
      dateTo: null,
      search: ''
    });
  }, []);

  /** Toggle Filter visibility **/
  const filterVisibilityChange = () => {
    setShowFilter(!showFilter);
  };

  /**
   * Toggle Filter visibility
   * @param {array} values - filter values
   **/
  const onFilterChange = (values) => {
    setSettings(values);
  };

  /**
   * Update search state & preform search of invoices
   * @param {string} search - search param
   **/
  const onInvoicesSearch = (search = '') => {
    setInvoicesSearch(search);
    setPage(0);
    const query = InvoicesQueryFormatter(settings);
    applyInvoicesFilter({
      page: 0,
      filter: query,
      search: search,
      dateFrom: invoiceDates.dateFrom,
      dateTo: invoiceDates.dateTo
    });
  };

  /** Apply Invoices Filter **/
  const applyFilter = () => {
    const query = InvoicesQueryFormatter(settings);
    setPage(0);
    applyInvoicesFilter({
      page: 0,
      filter: query,
      dateFrom: invoiceDates.dateFrom,
      dateTo: invoiceDates.dateTo,
      search: invoicesSearch
    });
  };

  const onEndReached = () => {
    const query = InvoicesQueryFormatter(settings);
    setPage((page) => page + 1);
    endReached({
      page: page + 1,
      filter: query,
      dateFrom: invoiceDates.dateFrom,
      dateTo: invoiceDates.dateTo,
      search: invoicesSearch
    });
  };

  /**
   * Invoice Date filter changes
   * @param {string}  type
   * @param {object} value
   */
  const onDateChange = (type, value) => {
    setInvoiceDates({
      ...invoiceDates,
      [type]: value
    });
  };

  /**
   * Invoice Filter component Props
   */
  const invoiceFilterProps = {
    visible: showFilter,
    filterValues: settings,
    invoiceDates: invoiceDates,
    onDateChange: onDateChange,
    filterChanged: onFilterChange,
    applyFilter: applyFilter
  };

  return (
    <div className="list-view grow">
      <SearchBarFilter
        search={invoicesSearch}
        onSearch={(e) => onInvoicesSearch(e.target.value)}
        onFilterPress={filterVisibilityChange}
        onClose={() => onInvoicesSearch('')}>
        <AppointmentFilter {...invoiceFilterProps} />
      </SearchBarFilter>
      <InvoiceCardList
        loading={loading}
        isLastPage={lastPage === page}
        invoices={invoices}
        isEndReached={onEndReached}
      />
    </div>
  );
};

export default InvoicesWrapper;
