import React, { useState } from 'react';

/** load components **/
import { Button } from '@components/Common/Button';
import { BUTTON_TYPES } from '@constants';
import { Input } from '@components/Common/Input';
import { InputList } from '@components/Common/Input/InputList';
import { Field, Formik, Form } from 'formik';
/** load icons **/
import Shield from '@assets/img/shield-lock-outline.svg';
import Eye from '@assets/svg/eye.svg';
import KeyIcon from 'mdi-react/KeyIcon';
import { t } from '@helpers/i18n';

/** load styles **/
import './style.scss';

const ProfilePassword = ({ changeProfilePassword = () => {} }) => {
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);

  /** show current password **/
  const onTogglePassView = () => {
    setShowPass(!showPass);
  };

  /** show new password **/
  const onToggleNewPassView = () => {
    setShowNewPass(!showNewPass);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        password: '',
        old_password: ''
      }}
      onSubmit={(values) => {
        changeProfilePassword(values);
      }}>
      <Form className={'edit-password-form'}>
        <div className="edit-row-password">
          <InputList class={'password-inputs'}>
            <img className="shield" src={Shield} alt="shield" />
            <div className="input-eye">
              <Field
                label={t('current_password')}
                name="old_password"
                inputType={showPass ? 'text' : 'password'}
                component={Input}
              />
              <img src={Eye} onClick={onTogglePassView} alt="eye" />
            </div>
            <div className="input-eye">
              <Field
                label={t('new-password')}
                name="password"
                inputType={showNewPass ? 'text' : 'password'}
                component={Input}
                className="input_new"
              />
              <img src={Eye} onClick={onToggleNewPassView} alt="eye" />
            </div>
          </InputList>
          <Button
            className="is-success"
            icon={<KeyIcon />}
            type={BUTTON_TYPES.SUCCESS}
            text={t('update_password')}
            isSubmit
            name="pass-success"
            value="pass-success"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default ProfilePassword;
