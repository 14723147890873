import React from 'react';
import Settings from 'mdi-react/TuneIcon';

import SearchBar from '../index';

import './SrachBarFilter.styles.scss';

const SearchBarFilter = ({ search, onSearch = () => {}, onClose = () => {}, onFilterPress, children }) => {
  return (
    <div className={'search_list_filter'}>
      <div className={'search-container'}>
        <SearchBar search={search} onSearch={onSearch} onClose={onClose}>
          <Settings onClick={onFilterPress} className="settings-icon" />
        </SearchBar>
      </div>
      {children}
    </div>
  );
};

export default SearchBarFilter;
