import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Api } from '@api';

/** load components **/
import SimpleCard from '@components/Card/SimpleCard';
import InvoiceLeftBlock from '@components/Invoice/Card/LeftBlock/InvoiceLeftBlock.component';
import InvoicePrimaryContent from '@components/Invoice/Card/PrimaryContent/InvoicePrimaryContent.component';
import InvoiceRightBlock from '@components/Invoice/Card/RightBlock/InvoiceRightBlock.component';

import './InvoiceCard.styles.scss';

const InvoiceCard = ({ invoice = {} }) => {
  const { id, read } = invoice;

  const history = useHistory();

  /**
   * on Invoice click @event
   * mark invoice as read & go to invoice detailed view
   **/
  const onInvoiceClick = async () => {
    await Api.invoices.markInvoiceRead({ invoiceId: id });
    history.push(`/invoices/${id}/info`);
  };

  /**
   * Render Invoice Card Left Block
   * @returns {JSX.Element}
   **/
  const renderLeftInfoBlock = () => {
    return <InvoiceLeftBlock invoice={invoice} />;
  };

  /**
   * Render Invoice Card Left Block
   * @returns {JSX.Element}
   **/
  const renderRightInfoBlock = () => {
    return <InvoiceRightBlock invoice={invoice} />;
  };

  /**
   * Render Appointment Card Primary content
   * @returns {JSX.Element}
   **/
  const renderPrimaryContent = () => {
    return <InvoicePrimaryContent invoice={invoice} />;
  };
  return (
    <article className={classNames(`invoice-card invoice-status-green`, { 'invoice-unread': !read })}>
      <SimpleCard
        onClick={onInvoiceClick}
        primaryContent={renderPrimaryContent}
        renderLeft={renderLeftInfoBlock}
        renderRight={renderRightInfoBlock}></SimpleCard>
    </article>
  );
};

export default InvoiceCard;
