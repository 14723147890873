import React, { useCallback, useEffect, useState } from 'react';
import './Reporting.styles.scss';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ReportingCardComponent from '@components/Reporting/ReportingCard/ReportingCard.component';
import { LicensePlate } from '@components/Car/LicensePlate';
import { Button } from '@components/Common/Button';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import LocaleData from 'dayjs/plugin/localeData';
import MileagePerDayComponent from '@components/Reporting/MileagePerDay';
import OverviewTableComponent from '@components/Reporting/OverviewTable';
import MileagePerDayOfTheWeekComponent from '@components/Reporting/MileagePerDayOfTheWeek';
import WeekendMileageTable from '@components/Reporting/WeekendMileageTable';
import {
  PerDayMileageFormatters,
  PerDayOfTheWeekFormatters,
  OverviewTableFormatters,
  WeekendMileageFormatters
} from '@services/formatters/reporting';
import { GET_REPORTING_DATA_REQUEST, UPDATE_REPORTING_CONSTANTS_REQUEST } from '@constants';
import AnimatedLoader from '@components/Common/AnimatedLoader';
import ReportingInfoTooltipComponent from '@components/Reporting/ReportingInfoTooltip';
import { TailSpin } from 'react-loader-spinner';
import classnames from 'classnames';

const chartGreen = '#9cc765';
const chartYellow = '#FFD500';
const chartRed = '#E84D0E';
const chartBlue = '#2D7695';
const chartDarkBlue = '#00273C';
const dateFormat = 'YYYY-MM-DD';

const ReportingContainer = () => {
  dayjs.extend(CustomParseFormat);
  dayjs.extend(UpdateLocale);
  dayjs.extend(LocaleData);

  const { profile } = useSelector((state) => state.profile);
  const name = profile?.name || '';
  const surname = profile?.surname || '';
  const customerNumber = profile?.customerNumber || '';

  const [month, setMonth] = useState(dayjs());
  const [dataPerWeek, setDataPerWeek] = useState([]);
  const [dataAveragePerWeek, setAverageDataPerWeek] = useState([]);
  const [weekendData, setWeekendData] = useState({});
  const [lastTwelveMoths, setLastTwelveMonths] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [monthlyMileage, setMonthlyMileage] = useState({});
  const [overviewTableData, setOverviewTableData] = useState({});
  const [cars, setCars] = useState([]);
  const dispatch = useDispatch();
  const { reportData, isLoading, updatingConstants } = useSelector((state) => state.reporting);

  const chartColors = [chartGreen, chartYellow, chartRed, chartBlue, chartDarkBlue];

  const germanMonths = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];

  /**
   * Get past 12 months for report month select
   */
  const fillPastMonths = () => {
    const pastTwelveMonths = [];
    const currentMoth = dayjs().month();
    pastTwelveMonths.push({ label: `Aktueller Monat `, value: month });
    for (let i = 1; i < 12; i++) {
      let calculatedMonth;
      if (i <= currentMoth) {
        calculatedMonth = dayjs().month((12 + currentMoth - i) % 12);
      } else {
        // preventing negative month index
        calculatedMonth = dayjs().month((12 + currentMoth - i) % 12);
      }

      const valueDate = dayjs().month(calculatedMonth.month());

      pastTwelveMonths.push({
        label: `${germanMonths[calculatedMonth.month()]} (${calculatedMonth.daysInMonth()} Tage)`,
        value: valueDate.date(calculatedMonth.daysInMonth())
      });
    }
    setLastTwelveMonths(pastTwelveMonths);
  };

  /**
   * Parse and set monthly mileage data
   * @param dailyReport
   */
  const fillMonthlyMileage = (dailyReport) => {
    setMonthlyMileage(PerDayMileageFormatters.getMileagePerDayObject(dailyReport));
  };

  /**
   * Parse and set mileage per weekday data
   * @param perWeekDayData
   */
  const fillMileagePerDayOfTheWeek = (perWeekDayData) => {
    setDataPerWeek(Object.values(PerDayOfTheWeekFormatters.getMileagePerDayOfTheWeekObject(perWeekDayData)));
    setAverageDataPerWeek(
      Object.values(PerDayOfTheWeekFormatters.getAverageMileagePerDayOfTheWeekObject(perWeekDayData))
    );
  };

  /**
   * Parse and fill weekend mileage data
   * @param weekendMileage
   */
  const fillWeekendMileage = (weekendMileage) => {
    setWeekendData(WeekendMileageFormatters.getWeekendMileageObject(weekendMileage));
  };

  /**
   * Parse and fill overview table data
   * @param tableData
   */
  const fillOverviewTableData = (tableData) => {
    setOverviewTableData(OverviewTableFormatters.getOverviewObject(tableData));
  };

  /**
   * Fetch reporting data on month change
   */
  useEffect(() => {
    dispatch({
      type: GET_REPORTING_DATA_REQUEST,
      payload: { date: month.format(dateFormat) }
    });
    setSelectedVehicles([]);
  }, [month]);

  /**
   * Parse fetched reporting data
   */
  useEffect(() => {
    if (Object.keys(reportData).length) {
      setCars(reportData.cars);
      if (selectedVehicles.length === 0 && reportData?.cars[0]?.licencePlate) {
        setSelectedVehicles([reportData?.cars[0]?.licencePlate]);
      }

      fillMonthlyMileage(reportData?.dailyReport);
      fillMileagePerDayOfTheWeek(reportData?.weekDayReport);
      fillWeekendMileage(reportData?.weekendReport);
      fillOverviewTableData(reportData?.tableReport);
    }
  }, [reportData]);

  /**
   * Initial effect, fills month select with past 12 months
   */
  useEffect(() => {
    fillPastMonths();
  }, []);

  /**
   * Add selected licence plate to the list
   * @param licencePlate
   */
  const addVehicle = (licencePlate) => {
    setSelectedVehicles([...selectedVehicles, licencePlate]);
  };

  /**
   * Remove selected vehicle from the list
   * @param licencePLate
   */
  const removeVehicle = (licencePLate) => {
    setSelectedVehicles(selectedVehicles.filter((pl) => pl !== licencePLate));
  };

  /**
   * On licence plate click handler
   * @param licencePlate
   */
  const toggleVehicle = (licencePlate) => {
    if (selectedVehicles.includes(licencePlate)) {
      removeVehicle(licencePlate);
      return;
    }
    addVehicle(licencePlate);
  };

  /**
   * get label for selected month
   * @param value
   * @returns {string}
   */
  const getMonthLabel = (value) => {
    return lastTwelveMoths.find((month) => month.value === value)?.label;
  };

  const onReportTableDataChange = (vehicleDataRowId, data) => {
    dispatch({
      type: UPDATE_REPORTING_CONSTANTS_REQUEST,
      payload: { date: month.format(dateFormat), data }
    });
  };

  /**
   * Select all button handler
   */
  const toggleSelectAllVehicles = () => {
    if (!reportData || reportData.cars.length === 0) {
      return;
    }
    if (selectedVehicles.length === reportData.cars.length) {
      setSelectedVehicles([reportData.cars[0]?.licencePlate]);
    } else {
      setSelectedVehicles(reportData.cars.map((veh) => veh.licencePlate));
    }
  };

  /**
   * Licence plate component render function
   * @type {function(): unknown[]}
   */
  const renderLicencePLate = useCallback(() => {
    return cars.map((veh, index) => {
      const indexOfPlate = selectedVehicles.indexOf(veh.licencePlate);
      return (
        <Grid item xs={2} paddingTop={5} key={index.toString()}>
          <div
            style={{
              borderBottomColor: indexOfPlate > -1 ? chartColors[indexOfPlate % chartColors.length] : 'transparent'
            }}
            className={classnames(
              selectedVehicles.includes(veh.licencePlate) ? 'selected-vehicle' : '',
              'licence-plate-container'
            )}>
            <LicensePlate
              license={veh.licencePlate}
              key={index.toString()}
              country="D"
              onClick={() => toggleVehicle(veh.licencePlate)}
            />
          </div>
        </Grid>
      );
    });
  }, [selectedVehicles]);

  /**
   * Card header title component render function
   * @param text
   * @param tooltipText
   * @returns {JSX.Element}
   */
  const titleComponent = (text, tooltipText = '') => (
    <h1 className={'card-header-title'}>
      {text}
      {tooltipText && <ReportingInfoTooltipComponent tooltipText={tooltipText} />}
    </h1>
  );

  /**
   * Report month select change handler
   * @param event
   */
  const handleChange = (event) => {
    setMonth(event.target.value);
  };

  if (isLoading) {
    return <AnimatedLoader />;
  }

  return (
    <div className={'main-container'} style={updatingConstants ? { overflow: 'hidden' } : {}}>
      {updatingConstants && (
        <div className="loading-overlay">
          <TailSpin color="#00BFFF" height={100} width={100} />
        </div>
      )}
      <div className="stat-container">
        <Grid container spacing={2}>
          {/* Customer info and vehicle select */}
          <Grid item xs={12}>
            <ReportingCardComponent headerContentLeft={titleComponent('Kunde Informationen')}>
              <Grid container padding={3}>
                <Grid item xs={12} xl={5}>
                  <h1 className={'customer-name'}>{`${name || ''} ${surname || ''}`}</h1>
                  <p className={'customer-number'}>{customerNumber}</p>
                  <FormControl sx={{ m: 1, width: 250, marginTop: 5 }}>
                    <p id="demo-simple-select-helper-label">Reporting-Zeitraum:</p>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={month}
                      size={'small'}
                      inputProps={{
                        'aria-label': 'none'
                      }}
                      onChange={handleChange}>
                      {lastTwelveMoths.map((m, index) => (
                        <MenuItem value={m.value} key={index.toString()}>
                          {m.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} xl={7} paddingTop={1}>
                  <Grid container>
                    <Grid item xs={10}>
                      <p>Bitte wählen Sie die anzuzeigenden Fahrzeuge</p>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        isSmall
                        type={'is-warning'}
                        text={'alle aus-/abwählen'}
                        onClick={toggleSelectAllVehicles}
                      />
                    </Grid>
                    {renderLicencePLate()}
                  </Grid>
                </Grid>
              </Grid>
            </ReportingCardComponent>
          </Grid>

          {/* Stat table */}
          <OverviewTableComponent
            reportConstants={reportData?.constants || {}}
            tableData={overviewTableData}
            onReportTableDataChange={onReportTableDataChange}
            chartColors={chartColors}
            selectedVehicles={selectedVehicles}
            month={month}
            getMonthLabel={getMonthLabel}
            titleComponent={titleComponent}
          />

          {/* Mileage graph */}
          <MileagePerDayComponent
            chartColors={chartColors}
            monthlyMileage={monthlyMileage}
            reportData={cars}
            titleComponent={titleComponent}
            month={month}
            getMonthLabel={getMonthLabel}
            selectedVehicles={selectedVehicles}
          />

          {/* Weekend drive table */}
          <WeekendMileageTable
            chartColors={chartColors}
            weekendMileage={weekendData}
            titleComponent={titleComponent}
            month={month}
            getMonthLabel={getMonthLabel}
            selectedVehicles={selectedVehicles}
          />

          {/* Weekend drive graph */}
          <MileagePerDayOfTheWeekComponent
            chartColors={chartColors}
            titleComponent={titleComponent}
            selectedVehicles={selectedVehicles}
            dataPerWeek={dataPerWeek}
            month={month}
            getMonthLabel={getMonthLabel}
            dataAveragePerWeek={dataAveragePerWeek}
          />
        </Grid>
      </div>
    </div>
  );
};

export default ReportingContainer;
