import React from 'react';

const ImageCmp = ({ onClick, className, src, isBlob = true }) => {
  return (
    src && (
      <div className={`img-wrapper ${className}`} onClick={onClick}>
        <img src={isBlob ? `data:image/png;base64,${src}` : src} />
      </div>
    )
  );
};

export default ImageCmp;
