import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/** load components **/
import Modal from '@components/Common/Modal';
import Dropdown from '@components/Common/DropDown';
import { Button } from '@components/Common/Button';
import { BUTTON_TYPES } from '@constants';

/** load services **/
import { t } from '@helpers/i18n';
import * as appointmentsActions from '@store/appointments/actions';

/** load icons **/
import CheckIcon from 'mdi-react/CheckIcon';

import './AppointmentCancel.styles.scss';

const AppointmentCancel = (props) => {
  const { visible = false, onClose = () => {}, appointment = {} } = props;

  /**
   * Redux selectors
   */
  const { auth } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [formErrors, setFormErrors] = useState(null);
  // reasons to cancel appointment
  const appointmentReasonOptions = [
    { value: null, label: '-' },
    { value: 0, label: t('appointment_cancel_reason1') },
    { value: 1, label: t('appointment_cancel_reason2') },
    { value: 2, label: t('appointment_cancel_reason3') }
  ];

  const onChange = (option) => {
    setReason(option);
  };
  /**
   * Callback func when modal is closed
   * @param {boolean} resetData - detect if we should reset appointments list
   **/
  const onModalClose = (doNotReset = true) => {
    setReason('');
    setFormErrors('');
    onClose(!doNotReset);
  };

  /**
   * Appointment cancel callback func
   **/
  const onAppointmentCancelSuccess = () => {
    onModalClose(false);
  };

  /**
   * Cancel Appointment action
   **/
  const onSaveClick = () => {
    if (reason && reason.value !== null) {
      dispatch(
        appointmentsActions.appointmentCancel({
          reasonId: reason.value,
          appointmentId: appointment.id,
          userId: auth.user.id,
          onSuccess: onAppointmentCancelSuccess
        })
      );
    } else {
      setFormErrors('appointment_reason_required');
    }
  };

  return (
    <Modal className={'appointment-cancel-modal'} isVisible={visible} onClose={() => onModalClose(true)}>
      <div className={'cancel-appointment-form'}>
        <p className="title">{t('appointment_cancel_modal_title')}</p>
        <Dropdown
          className={'appointment-options'}
          value={reason}
          keyValue={'label'}
          options={appointmentReasonOptions}
          onChange={onChange}></Dropdown>
        {formErrors && <p className={'error-message'}>{t(formErrors)}</p>}
        <Button
          className="is-success"
          icon={<CheckIcon />}
          name="success"
          value="btn-success"
          type={BUTTON_TYPES.SUCCESS}
          text={t('save')}
          isSubmit
          isSmall
          onClick={onSaveClick}
        />
      </div>
    </Modal>
  );
};

export default AppointmentCancel;
