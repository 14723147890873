import { format } from 'date-fns';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const mileageFormatter = (mileage) => {
  if (mileage) {
    const formattedMileage = mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${formattedMileage}`;
  }
  return `0`;
};

// todo check sometimes creation date is passed as 00
const hasNullDate = (date) => {
  return date === '0000-00-00 00:00:00';
};

export const creationDateFormatter = (creationDate) => {
  if (hasNullDate(creationDate)) {
    return format(new Date(), 'dd.MM.yyy | HH:mm');
  }
  return format(new Date(creationDate), 'dd.MM.yyy | HH:mm');
};

/** * formatting currency amounts **/
export const currencyFormatter = (currencySum) => {
  if (currencySum !== null) {
    const numericalSum = currencySum.toFixed(2);
    const replacedDecimals = numericalSum.replace('.', ',');
    return replacedDecimals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return '0.00';
};

export const formatDate = (date, dateFormat) => {
  if (hasNullDate(date)) {
    return format(new Date(), dateFormat);
  }
  return format(new Date(date), dateFormat);
};

export const dateFormatted = (dateObj) => {
  return formatDate(dateObj, 'dd.MM.yyy');
};

export const formatTimeFromUTC = (dateObj) => {
  return dayjs.utc(dateObj).tz('Europe/Berlin').format('HH:mm');
};

export function formatMileage(mileage, locale = 'de-DE') {
  let normalizedMileage;

  if (typeof mileage === 'string') {
    // Remove thousands separators and replace English decimal point with a dot
    normalizedMileage = mileage.replace(/,/g, '').replace(/\./g, '.');
  } else {
    normalizedMileage = mileage;
  }

  // Convert to number
  const mileageNumber = parseFloat(normalizedMileage);

  // Check if the conversion is successful
  if (isNaN(mileageNumber)) {
    return '';
  }

  // Define locale-specific options
  const options = {
    maximumFractionDigits: 0
  };

  // Format the number based on the locale
  return mileageNumber.toLocaleString(locale, options);
}
