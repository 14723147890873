import React, { useState } from 'react';
/** load components **/
import AppointmentExpandableCard from '../Card/ExpandableCard/AppointmentExpandableCard.component';
import AppointmentRightBlock from '@components/Appointment/Card/ExpandableCard/RightBlock/AppointmentRightBlock.component';
import AppointmentLeftBlock from '@components/Appointment/Card/ExpandableCard/LeftBlock/AppointmentLeftBlock.component';
import AppointmentPrimaryContent from '@components/Appointment/Card/ExpandableCard/PrimaryContent/AppointmentPrimary.component';
import AppointmentDetailedView from '@components/Appointment/Card/ExpandableCard/DetailedView/AppointmentDetailedView.component';

/** load formatters **/
import { AppointmentsCardFormatters } from '@services/formatters/appointments';

import './AppointmentCard.styles.scss';

const AppointmentCard = ({ appointment = {}, resetAppointmentsList = () => {} }) => {
  const { appState } = appointment;

  const [expanded, setExpanded] = useState(false);

  const appointmentBorderClass = AppointmentsCardFormatters.AppointmentSideColor(appState);

  /**
   * Render Appointment Card Left Block
   * @returns {JSX.Element}
   **/
  const renderLeftInfoBlock = () => {
    return <AppointmentLeftBlock appointment={appointment} />;
  };

  /** Render Appointment Card Left Block
   * @returns {JSX.Element}
   **/
  const renderRightInfoBlock = () => {
    return <AppointmentRightBlock appointment={appointment} expanded={expanded} />;
  };

  /**
   * Render Appointment Card Primary content
   * @returns {JSX.Element}
   **/
  const renderPrimaryContent = () => {
    return <AppointmentPrimaryContent appointment={appointment} expanded={expanded} />;
  };

  /**
   * Render Expanded Appointment Card
   * @returns {JSX.Element}
   **/
  const renderBottomContent = () => {
    return <AppointmentDetailedView appointment={appointment} resetAppointmentsList={resetAppointmentsList} />;
  };

  /**
   * Appointment clicked callback
   * toggles view between normal & expanded card
   **/
  const onAppointmentClick = () => {
    setExpanded(!expanded);
  };

  return (
    <article className={`appointment-card appointment_status_${appointmentBorderClass}`}>
      <AppointmentExpandableCard
        id={appointment.id}
        expanded={expanded}
        primaryContent={renderPrimaryContent}
        renderLeft={renderLeftInfoBlock}
        renderRight={renderRightInfoBlock}
        onAppointmentClick={onAppointmentClick}>
        {renderBottomContent()}
      </AppointmentExpandableCard>
    </article>
  );
};

export default AppointmentCard;
