import React from 'react';

import ImageCmp from '@components/Common/Image';
import NoLicenseBack from '@assets/img/no-license-back.png';
import NoLicenseFront from '@assets/img/no-license-front.png';

import './EmployeeLicenseImages.styles.scss';

const EmployeeLicenseImages = ({ driverLicense = {}, onImagePreview = () => {} }) => {
  const { frontImage, backImage } = driverLicense;

  const getImageProps = (image, alternativeImage) => ({
    src: image?.blob ? image.blob : alternativeImage,
    isBlob: !!image?.blob,
    className: `license-img ${image?.blob ? '' : 'license-img--default'}`,
    onClick: () => (image?.blob ? onImagePreview(0, image) : '')
  });

  return (
    <div className="license-images">
      {<ImageCmp {...getImageProps(frontImage, NoLicenseFront)} />}
      {<ImageCmp {...getImageProps(backImage, NoLicenseBack)} />}
    </div>
  );
};

export default EmployeeLicenseImages;
