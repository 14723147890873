import React from 'react';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import { WorkingHours } from '@components/WorkingHours';

import { fuelTypes } from '@helpers/constants/fuelTypes';

import './StationInfo.styles.scss';

const StationInfo = ({ station }) => {
  const getFuelType = (type) => {
    return type !== 'diesel' ? `Super ${type}` : type;
  };

  const getPrice = (price) => {
    return price ? price.toString().slice(0, -1) : '-,--';
  };

  const getSubPrice = (price) => {
    return price ? price.toString().slice(-1) : '-';
  };

  const getPriceByType = (fuelType) => {
    if (!station) {
      return 0;
    }
    return station[`price-${fuelType}`] || 0;
  };

  const renderPrices = () => {
    return fuelTypes.map((type, index) => {
      const pricePerType = getPriceByType(type.value);
      return (
        <div key={index} className={`price-line elem-${index}`}>
          <div className="fuel-type">{getFuelType(type.value)}</div>
          <div className="fuel-price">
            {getPrice(pricePerType)}
            <span>{getSubPrice(pricePerType)}</span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="station-info">
      <div className="service-name">{station.brand}</div>
      <div className="service-location">
        <div className="location-icon">
          <MapMarkerOutlineIcon />
        </div>
        <div className="location-text">{station.address}</div>
      </div>
      {<WorkingHours station={true} data={station['opening-hours']} />}
      {renderPrices()}
      <div className="spacer"></div>
    </div>
  );
};

export default StationInfo;
