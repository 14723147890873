import React from 'react';
import TimeTrackingFrame from '@components/Employee/EmployeeTimeTrackingDetails/TimeTrackingFrame';
import TimeTrackingHeader from '@components/Employee/EmployeeTimeTrackingDetails/TimeTrackingHeader';
import './EmployeeTimeTrackingDetails.styles.scss';

const EmployeeTimeTrackingDetails = ({ trackingDetails = {} }) => {
  const { date, begin, end, seconds, time_trackings, inProgress } = trackingDetails;
  return (
    <div className={'employee-tracking-details'}>
      <TimeTrackingHeader date={date} begin={begin} end={end} seconds={seconds} inProgress={inProgress} />
      {time_trackings?.map((tracking, index) => {
        return (
          <TimeTrackingFrame
            key={index}
            begin={tracking?.begin}
            end={tracking?.end}
            seconds={tracking?.seconds.toString()}
            beginAddress={tracking?.begin_address}
            endAddress={tracking?.end_address}
            inProgress={tracking?.inProgress}
          />
        );
      })}
    </div>
  );
};

export default React.memo(EmployeeTimeTrackingDetails);
