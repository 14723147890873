import React from 'react';

/** load components **/
import Avatar from '@components/User/Avatar';
import { LicensePlate } from '@components/Car/LicensePlate';

import './VehicleInfoBlock.styles.scss';

const VehicleInfoBlock = ({ name, license, avatar, type, tags }) => {
  const renderTags = () => {
    if (tags && tags?.length) {
      return <div className="info-tags">{tags.map((tag) => tag.name).join(' | ')}</div>;
    }
  };

  return (
    <div className="vehicle-info-row">
      <Avatar src={avatar} />
      <div className={'user-info-block'}>
        <div className="info-data">
          <div className="info-name">
            <p className="name">
              {name} {type}
            </p>
          </div>
          {renderTags()}
          <LicensePlate country="D" license={license} />
        </div>
      </div>
    </div>
  );
};

export default VehicleInfoBlock;
