import React from 'react';
/** load components **/
import Toggler from '@components/Common/Toggler';

/** Load translation helper **/
import { t } from '@helpers/i18n';

const VehicleFilterForm = ({ settings, onChange, employeeFilterTitle = {} }) => {
  return (
    <div className="filter-rows">
      <div className="row">
        <div className="toggle">
          <label>
            <p>{t('show_all')}</p>
            <Toggler onChange={() => onChange('all')} checked={settings[0].value} />
          </label>
          <label>
            <p>{(employeeFilterTitle && t(employeeFilterTitle?.complete)) ?? t('data_complete')}</p>
            <Toggler onChange={() => onChange('complete')} checked={settings[1].value} />
          </label>
          <label>
            <p>{(employeeFilterTitle && t(employeeFilterTitle?.incomplete)) ?? t('data_incomplete')}</p>
            <Toggler onChange={() => onChange('incomplete')} checked={settings[2].value} />
          </label>
        </div>
      </div>
    </div>
  );
};

export default VehicleFilterForm;
