import React from 'react';
import './DailyRentNoReport.styles.scss';

const DailyRentNoReport = ({ title = '', message = '' }) => {
  return (
    <div className="report-box-container">
      <div className="title-box">{title}</div>
      <div className="message-box">{message}</div>
    </div>
  );
};

export default DailyRentNoReport;
