import React from 'react';
/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';
import InvoiceFilterForm from '@components/Invoice/Filter/FilterForm/InvoiceFilterForm.component';

/** import services **/
import { InvoicesFilterFormatter } from '@services/formatters/invoices/InvoicesFilter.formatter';
import { t } from '@helpers/i18n';

const InvoiceFilter = ({
  filterValues = [],
  visible = false,
  invoiceDates = {},
  onDateChange = () => {},
  filterChanged = () => {},
  applyFilter = () => {}
}) => {
  const filterChange = (e) => {
    const updatedFilters = InvoicesFilterFormatter(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible && (
      <div className={'list-filter'}>
        <InvoiceFilterForm
          invoiceDates={invoiceDates}
          filters={filterValues}
          onChange={filterChange}
          onDateChange={onDateChange}
        />
        <ApplyButton onClick={applyFilter} text={t('apply')} />
      </div>
    )
  );
};

export default InvoiceFilter;
