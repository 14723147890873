export const CARD_TYPES = {
  RED_WITH_BACKGROUND: 'red-with-background',
  RED_ARCHIVE: 'red_archive',
  GREEN_WHITE: 'green_white',
  YELLOW_WARN: 'yellow_warn',
  GREEN_TICK: 'green_tick',
  OBJECTED: 'objected',
  GREEN_CHECK: 'red-with-check',
  YELLOW: 'yellow',
  GREEN: 'green',
  WHITE: 'white',
  EMPTY: 'empty',
  BLUE: 'blue',
  GRAY: 'grey',
  RED: 'red'
};
