import React, { useCallback } from 'react';

/* Importing the LazyLoadingList and TicketCard components from the components folder. */
import LazyLoadingList from '@components/LazyLoadingList';
import TicketCard from '@components/Ticket/Card';

const CardListTicket = (props) => {
  const {
    loading = false,
    tickets,
    lastPage = false,
    userRole,
    isEndReached = () => {},
    onTicketCardClick = () => {}
  } = props;

  const renderCardListTicket = (ticket, i) => (
    <TicketCard
      key={i}
      state={ticket?.state}
      fileNumber={ticket?.['government-reference']}
      licencePlate={ticket?.['licence-plate']}
      userAvatar={ticket?.user?.avatar}
      date={ticket?.['event-date']}
      driverName={ticket?.user?.name}
      driverSurname={ticket?.user?.surname}
      userRole={userRole}
      onClick={() => onTicketCardClick(ticket?.id)}
    />
  );

  /**
   * Render Lazy Loading Card list
   * @returns {JSX.Element}
   **/
  const renderContent = useCallback(() => {
    return (
      <LazyLoadingList
        data={tickets || []}
        loading={loading}
        isLastPage={lastPage}
        onEndReached={isEndReached}
        renderItem={renderCardListTicket}
      />
    );
  }, [loading, tickets]);

  return <>{renderContent()}</>;
};

export default CardListTicket;
