import React from 'react';

/** Load components **/
import Toggler from '@components/Common/Toggler';
import { t } from '@helpers/i18n';
import FilterDatePicker from '@components/Common/FilterDatePicker';

const DailyRentFilterForm = ({ filters = [], rentDates = {}, onChange = () => {}, onDateChange = () => {} }) => {
  return (
    <div className={'filter-rows'}>
      <div className="toggle">
        {filters.map((filterItem, index) => (
          <label key={index}>
            <p>{t(filterItem.filter)}</p>
            <Toggler id={index.toString()} checked={filterItem.value} onChange={onChange} />
          </label>
        ))}
        {filters[4].value && (
          <div className="date-picker">
            <FilterDatePicker dateFrom={rentDates.dateFrom} dateTo={rentDates.dateTo} onDateChange={onDateChange} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyRentFilterForm;
