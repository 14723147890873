import * as profileConstants from './constants';

export const profileGet = (payload) => ({ type: profileConstants.PROFILE_GET_REQUEST, payload });
export const profileGetSuccess = (payload) => ({ type: profileConstants.PROFILE_GET_SUCCESS, payload });
export const profileGetFail = (payload) => ({ type: profileConstants.PROFILE_GET_SUCCESS, payload });

export const profileGetLicense = (payload) => ({ type: profileConstants.PROFILE_GET_LICENSE_REQUEST, payload });
export const profileGetLicenseSuccess = (payload) => ({ type: profileConstants.PROFILE_GET_LICENSE_SUCCESS, payload });
export const profileGetLicenseFail = (payload) => ({ type: profileConstants.PROFILE_GET_LICENSE_FAIL, payload });

export const profileUpdate = (payload) => ({ type: profileConstants.PROFILE_UPDATE_REQUEST, payload });
export const profileUpdateSuccess = (payload) => ({ type: profileConstants.PROFILE_UPDATE_SUCCESS, payload });
export const profileUpdateFail = (payload) => ({ type: profileConstants.PROFILE_UPDATE_FAIL, payload });

export const profileUpdateLicense = (payload) => ({ type: profileConstants.PROFILE_UPDATE_LICENSE_REQUEST, payload });
export const profileUpdateLicenseSuccess = (payload) => ({
  type: profileConstants.PROFILE_UPDATE_LICENSE_SUCCESS,
  payload
});
export const profileUpdateLicenseFail = (payload) => ({ type: profileConstants.PROFILE_UPDATE_LICENSE_FAIL, payload });

export const setUser = (payload) => ({ type: profileConstants.SET_USER_REQUEST, payload });
export const setUserSuccess = (payload) => ({ type: profileConstants.SET_USER_SUCCESS, payload });
export const setUserFail = (error) => ({ type: profileConstants.SET_USER_FAIL, error });
export const clearUser = (payload) => ({ type: profileConstants.SET_USER_CLEAR, payload });

export const getTagsRequest = (payload) => ({ type: profileConstants.PROFILE_GET_TAGS_REQUEST, payload });
export const getTagsSuccess = (payload) => ({ type: profileConstants.PROFILE_GET_TAGS_SUCCESS, payload });
export const getTagsFail = (payload) => ({ type: profileConstants.PROFILE_GET_TAGS_FAIL, payload });

export const getPersonalId = (payload) => ({ type: profileConstants.PROFILE_GET_PERSONAL_ID_REQUEST, payload });
export const getPersonalIdSuccess = (payload) => ({ type: profileConstants.PROFILE_GET_PERSONAL_ID_SUCCESS, payload });
export const getPersonalIdFail = (payload) => ({ type: profileConstants.PROFILE_GET_PERSONAL_ID_FAIL, payload });

export const updatePersonalId = (payload) => ({ type: profileConstants.PROFILE_UPDATE_PERSONAL_ID_REQUEST, payload });
export const updatePersonalIdSuccess = (payload) => ({
  type: profileConstants.PROFILE_UPDATE_PERSONAL_ID_SUCCESS,
  payload
});
export const updatePersonalIdFail = (payload) => ({ type: profileConstants.PROFILE_UPDATE_PERSONAL_ID_FAIL, payload });
