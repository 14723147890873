import React, { useState } from 'react';
import { t } from 'helpers/i18n';

/** Load Icons **/
import PersonAvatar from '../../../assets/svg/signup-dude.svg';
import CheckIcon from 'mdi-react/CheckIcon';
import AtIcon from 'mdi-react/AtIcon';
import PoundIcon from 'mdi-react/PoundIcon';
import AccountDetailsIcon from 'mdi-react/AccountDetailsIcon';

/** Load containers **/
import BaseDataForm from './BaseData/BaseData';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import CustomerNumber from './CustomerNumber/CustomerNumber';
import PersonalInfo from './PersonalInfo/PersonalInfo';

/** load style **/
import './style.scss';

import { useHistory } from 'react-router-dom';

const SignUp = ({ signUpUserAction = () => {} }) => {
  // use history hook
  const history = useHistory();

  const [step, setStep] = useState(['baseData']);

  const [registrationData, setRegistrationData] = useState({
    email: '',
    phone: '',
    password: '',
    digit1: '',
    digit2: '',
    digit3: '',
    digit4: '',
    digit5: '',
    digit6: '',
    company: '',
    name: '',
    surname: '',
    address: '',
    language: '',
    stations: { value: 'mr', label: 'Mr.' },
    salutation: '',
    addressStreet: '',
    addressNumber: '',
    addressZipcode: '',
    addressCity: '',
    birthday: null
  });

  /**
   * Create & SignUp user
   */
  const onSignUpAction = () => {
    signUpUserAction(registrationData);
  };

  const goToLogin = () => {
    history.push('/');
  };

  /**
   * Stepper util
   * goes to step Back
   */
  const stepBackAction = () => {
    // removes last item from stepper
    const updatedStepper = step.slice(0, step.length - 1);
    setStep(updatedStepper);
  };

  /**
   * Stepper util
   * will move to next step & update registration info
   */
  const updateStepper = (nextStep, formData) => {
    setRegistrationData({
      ...registrationData,
      ...formData
    });
    setStep([...step, nextStep]);
  };

  /** Triggered on each input change
   *
   * @param field {string} - field type,check registration info for reference
   * @param value {string}
   */
  const fieldChanged = (field, value) => {
    setRegistrationData({
      ...registrationData,
      [field]: value
    });
  };

  /**
   * Stepper options component
   * @returns {object}
   */
  const formSteps = {
    baseData: <BaseDataForm registrationInfo={registrationData} goToLogin={goToLogin} nextStep={updateStepper} />,
    privacy: <PrivacyPolicy nextStep={updateStepper} stepBack={goToLogin} />,
    customerNumber: (
      <CustomerNumber
        registrationInfo={registrationData}
        fieldChanged={fieldChanged}
        nextStep={updateStepper}
        stepBack={stepBackAction}
      />
    ),
    personalInfo: (
      <PersonalInfo
        registrationInfo={registrationData}
        fieldChanged={fieldChanged}
        nextStep={onSignUpAction}
        stepBack={stepBackAction}
      />
    )
  };

  /**
   * Renders active class when user
   * moves to corresponding step
   */
  const activeClass = (curStep) => {
    if (step.length === curStep) {
      return 'step_number';
    }
    if (step.length > curStep) {
      return 'step_number_2';
    }
    return '';
  };

  /**
   * Renders Correct icon for current step
   */
  const activeStep = (stepNumber) => {
    return step.length <= stepNumber;
  };

  /**
   * Renders Stepper for SignUp
   * @returns {JSX.Element}
   */
  const renderStepper = () => {
    return (
      <div className="stepper">
        <div className={`step ${activeClass(1)}`}>
          <div className="icon-container">{activeStep(1) ? <AtIcon /> : <CheckIcon />}</div>
          <div className="step-label">{t('base-data')}</div>
        </div>
        <div className="dashed-line" />
        <div className={`step ${activeClass(3)}`}>
          <div className="icon-container">{activeStep(3) ? <PoundIcon /> : <CheckIcon />}</div>
          <div className="step-label">{t('customer-number')}</div>
        </div>
        <div className="dashed-line" />
        <div className={`step ${activeClass(4)}`}>
          <div className="icon-container">{activeStep(4) ? <AccountDetailsIcon /> : <CheckIcon />}</div>
          <div className="step-label">{t('personal-information')}</div>
        </div>
      </div>
    );
  };

  const stepperForm = (currentStep) => {
    return formSteps[currentStep[currentStep.length - 1]];
  };

  const renderLeftBlock = () => {
    return (
      <div className={'left-block'}>
        {renderStepper()}
        <div className={'logo-wrapper'}>
          <img src={PersonAvatar} alt="Person Avatar" />
        </div>
      </div>
    );
  };

  return (
    <div className="sign-up-container login-container">
      {renderLeftBlock()}
      <div className="rectangle" />
      <div className={'right-block'}>{stepperForm(step)}</div>
    </div>
  );
};

export default SignUp;
