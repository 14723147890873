import React from 'react';
/** load components */
import Grid from '@mui/material/Grid';
import TagsSearch from '@components/Vehicle/General/Tags/TagsSelect/TagsSelect.component';
import TagsList from '@components/Vehicle/General/Tags/TagsList/TagsList.component';
/** load vehicle svg **/
import { ReactComponent as VehicleIcon } from '../../../../../assets/svg/add-vehicle.svg';

const TagsFormContainer = ({ tags, onAddTag, onTagRemove }) => {
  return (
    <>
      <TagsSearch onAddTag={onAddTag} />
      <Grid container>
        <Grid item xs={6}>
          <TagsList tags={tags} onRemove={onTagRemove} />
        </Grid>
        <Grid item xs={6}>
          <VehicleIcon />
        </Grid>
      </Grid>
    </>
  );
};

export default TagsFormContainer;
