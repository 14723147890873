import React, { useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_INITIAL_LINK_SET } from '@store/auth/constants';
/** load pages **/
import Login from '@containers/Login';
import SignUp from '@containers/SignUp';
import MapContainer from '@containers/Map';
import CarCheckList from '@containers/CarCheck/CarCheckList';
import Profile from '@containers/Profile';
import PersonalInformationTab from '@containers/Profile/PersonalInformationTab';
import DamageListContainer from '@containers/Damages/DamageList/DamageList.container';
import DamageDetails from '@containers/Damages/Tabs/DamageDetails';
import { Damages } from '@containers/Damages';
import DamagePartlight from '@containers/Damages/Tabs/DamagePartlight';
import DamagePictures from '@containers/Damages/Tabs/DamagePictures';
import ReportingContainer from '@containers/Reporting';
import DamageReclamationContainer from '@containers/Damages/Reclamation';
import VehicleListContainer from '@containers/Vehicles/VehicleList';
import DriversLicenseTab from '@containers/Profile/DriversLicenseTab';
import NotFoundPage from '@containers/NotFoundPage';
import Vehicle from '@containers/Vehicles/Tabs';
import EmployeeListContainer from '@containers/Employees/EmployeeList';
import Employee from '@containers/Employees/Employee';
import BusinessInfoTab from '@containers/Profile/BusinessInfoTab';
import SettingsTab from '@containers/Profile/SettingsTab';
import TripsListContainer from '@containers/Trips/TripsList';
import Trip from '@containers/Trips/Trip/Trip.container';
import AppointmentListContainer from '@containers/Appointments/AppointmentList';
import useModuleDetector from '@helpers/hooks/moduleDetectHook';
import * as tripsActions from '@store/trips/actions';
import moduleNames from '@helpers/constants/moduleNames';
import InvoicesList from '@containers/Invoices/InvoicesList';

/** load wrappers for public & private routes **/
import PrivateRoute from './guards/privateRouteHook';
import PublicRoute from './guards/publicRouteHook';
import Invoice from '@containers/Invoices/Invoice';
import TicketListContainer from '@containers/Tickets/TicketList';
import TicketDetailContainer from '@containers/Tickets/TicketDetail';
import AddTicketContainer from '@containers/Tickets/AddTicket';
import ResetPassword from '@containers/ResetPassword';
import TimeTrackingList from '@containers/TimeTracking/TimeTrackingList';
import PrivacyPolicy from '@containers/PrivacyPolicy';
import DailyRentList from '@containers/DailyRent/DailyRentList';
import DailyRentDetails from '@containers/DailyRent/DailyRentDetails';
import ContractDetails from '@containers/Contracts/ContractDetails';
import ContractList from '@containers/Contracts/ContractList';
import PersonalIdTab from '@containers/Profile/PerosnalIdTab';

const AppRouter = () => {
  const location = useLocation();
  const { initialRoute } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const moduleInfo = useModuleDetector();

  /**
   * If current path is the same as the initial route,
   * it resets initial route since it was resolved
   */
  useEffect(() => {
    if (location.pathname === initialRoute) {
      dispatch({ type: AUTH_INITIAL_LINK_SET, payload: '' });
    }
  }, [location?.pathname]);

  /**
   *  Resetting the trips list when the user navigates away from the trips module.
   */
  useEffect(() => {
    if (moduleInfo.previousModule === moduleNames.trips && moduleInfo.currentModule !== moduleNames.trips) {
      dispatch(tripsActions.resetTripsList());
    }
  }, [JSON.stringify(moduleInfo)]);
  /**
   * Gets initial path. It should resolve it if user is logged in,
   * or it will be the point of redirection once user logs in
   */
  useEffect(() => {
    dispatch({ type: AUTH_INITIAL_LINK_SET, payload: location.pathname });
  }, []);

  return (
    <Switch>
      {/** Public Routes **/}
      <PublicRoute exact path="/" startingRoute={initialRoute} component={Login} />
      <PublicRoute exact path="/register" component={SignUp} />

      {/** Route to Serve assetlinks.json **/}
      <PublicRoute
        exact
        path="/.well-known/assetlinks.json"
        component={() => {
          window.location.href = '/.well-known/assetlinks.json';
          return null;
        }}
      />
      {/** Route to Serve apple-app-site-association **/}
      <PublicRoute
        exact
        path="/apple-app-site-association"
        component={() => {
          window.location.href = '/apple-app-site-association';
          return null;
        }}
      />
      {/** Private Routes **/}
      <PrivateRoute path="/home" hasSidebar component={<MapContainer />} exact />
      <PrivateRoute path="/reporting" hasSidebar component={<ReportingContainer />} exact />
      <PrivateRoute path="/car-checks" hasSidebar component={<CarCheckList />} exact />
      <PrivateRoute path="/damages" hasSidebar component={<DamageListContainer />} exact />
      <PrivateRoute
        path="/damages/:id/details"
        component={<Damages tabs={true} render={<DamageDetails own={true} />} exact />}
      />
      <PrivateRoute
        path="/damages/:id/partlight"
        component={<Damages tabs={true} render={<DamagePartlight own={true} />} exact />}
      />
      <PrivateRoute
        path="/damages/:id/pictures"
        component={<Damages tabs={true} render={<DamagePictures own={true} />} exact />}
      />
      <PrivateRoute path="/damages/:id/reclamation" component={<DamageReclamationContainer />} exact />
      <PrivateRoute path="/vehicles" hasSidebar component={<VehicleListContainer />} exact />
      <PrivateRoute path="/vehicle/:id/info" component={<Vehicle />} />
      <PrivateRoute path="/employees" hasSidebar component={<EmployeeListContainer />} exact />
      <PrivateRoute path="/appointments" hasSidebar component={<AppointmentListContainer />} exact />
      <PrivateRoute path="/damages" hasSidebar component={<DamageListContainer />} exact />
      <PrivateRoute
        path="/damages/:id/details"
        component={<Damages tabs={true} render={<DamageDetails own={true} />} exact />}
      />
      <PrivateRoute
        path="/damages/:id/partlight"
        component={<Damages tabs={true} render={<DamagePartlight own={true} />} exact />}
      />
      <PrivateRoute
        path="/damages/:id/pictures"
        component={<Damages tabs={true} render={<DamagePictures own={true} />} exact />}
      />
      <Redirect exact from="/profile" to="/profile/personal-info" />
      <PrivateRoute
        path="/profile/personal-info"
        component={<Profile render={<PersonalInformationTab own={true} />} />}
        exact
      />
      <PrivateRoute
        path="/profile/drivers-license"
        component={<Profile render={<DriversLicenseTab own={true} />} exact />}
      />
      <PrivateRoute path="/profile/personal-id" component={<Profile render={<PersonalIdTab own={true} />} exact />} />
      <PrivateRoute
        path="/profile/business-info"
        component={<Profile render={<BusinessInfoTab own={true} />} exact />}
      />
      <PrivateRoute path="/profile/settings" component={<Profile render={<SettingsTab own={true} />} exact />} />

      <PrivateRoute path="/employees/:id/info" component={<Employee />} />
      <PrivateRoute path="/employees/:id/registration" component={<Employee />} />
      <PrivateRoute path="/employees/:id/vehicle" component={<Employee />} />
      <PrivateRoute path="/employees/:id/car-checks" component={<Employee />} />
      <PrivateRoute path="/employees/:id/damages" component={<Employee />} />
      <PrivateRoute path="/employees/:id/tracking" component={<Employee />} />
      <PrivateRoute path="/trips" hasSidebar component={<TripsListContainer />} exact />
      <PrivateRoute path="/trips/:id/info/:tripDate?" component={<Trip />} />

      {/*region Tickets*/}
      <PrivateRoute path="/tickets" hasSidebar component={<TicketListContainer />} exact />

      <PrivateRoute path="/tickets/add" hasSidebar component={<AddTicketContainer />} exact />
      <PrivateRoute path={'/tickets/:id'} exact component={<TicketDetailContainer />} />
      {/*endregion*/}

      <PrivateRoute path="/invoices/:id/info" component={<Invoice />} />
      <PrivateRoute path="/invoices" component={<InvoicesList />} />
      <PrivateRoute path="/tracking" component={<TimeTrackingList />} />
      <PrivateRoute path="/daily-rent/:rentalType/:id" component={<DailyRentDetails />} />
      <PrivateRoute path="/daily-rent/:rentalType/:id/invoice" component={<DailyRentDetails />} />
      <PrivateRoute path="/daily-rent/:rentalType/:id/handover-report/:carCheckId" component={<DailyRentDetails />} />
      <PrivateRoute path="/daily-rent/:rentalType/:id/return-report/:carCheckId" component={<DailyRentDetails />} />
      <PrivateRoute path="/daily-rent" component={<DailyRentList />} />
      <PrivateRoute path="/contracts/:contractId" component={<ContractDetails />} />
      <PrivateRoute path="/contracts" component={<ContractList />} />
      <PublicRoute path="/pw-reset" component={ResetPassword} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      {/** Not Found Page **/}
      <Route component={NotFoundPage}></Route>
    </Switch>
  );
};

export default AppRouter;
