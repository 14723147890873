import React from 'react';
import SimpleCard from '@components/Card/SimpleCard';

const AppointmentExpandableCard = ({
  id = '0',
  expanded = false,
  onAppointmentClick = () => [],
  primaryContent = () => [],
  renderLeft = () => [],
  renderRight = () => [],
  children = <></>
}) => {
  return (
    <SimpleCard
      id={id}
      onClick={onAppointmentClick}
      primaryContent={primaryContent}
      renderLeft={renderLeft}
      renderRight={renderRight}>
      {expanded && children}
    </SimpleCard>
  );
};

export default AppointmentExpandableCard;
